export default {
    // resume: 'Resume',
    // resumeComment: 'Resume Comment',
    // lastDegree: 'Last degree',
    // LastDegreeDateOfGraduation: 'Last degree date of graduation',
    // lastDegreeLevel: 'Last degree level',
    // lastDegreeField: 'Last degree field',
    // lastDegreeSpeciality: 'Last degree speciality',
    // lastDegreeInstitution: 'Last degree institution',
    // lastDegreeRed: 'Last degree red',
    // lastDegreeCertifiedCopy: 'Last degree certified copy',
    // lastDegreeCertifiedCopyComment: 'Last degree certified copy Comment',
    // lastDegreeTranscript: 'Last degree transcript',
    // lastDegreeTranscriptComment: 'Last degree transcript Comment',
    // doctorateInRussia: 'Doctorate in Russia',
    // institutionName: 'Institution name',
    // institutionCity: 'Institution city',
    // doctorateThesisDirectorName: 'Doctorate Thesis Director Name',
    // doctorateThesisDirectorEmail: 'Doctorate Thesis Director Email',
    // doctorateScholarship: 'Doctorate Scholarship',
    // yes: 'Yes',
    // no: 'No',
    // presentSituation: 'Present situation',
    // presentSituationStudent: 'Student',
    // presentSituationProfessional: 'Professional',
    // presentSituationJobSeeker: 'Job Seeker',
    // professionJobName: 'Profession Job Name',
    // professionDateOfHiring: 'Profession Date Of Hiring',
    // professionEmployerName: 'Profession Employer Name',
    // professionEmployerAddress: 'Profession Employer Address',
    // studentCardOrJobPermit: 'Student Card or Job Permit',
    // studentCardOrJobPermitComment: 'Student Card or Job Permit Comment',
    // formerScholarship: 'Former Scholarship',
    // frenchLanguageLevel: 'French Language Level',
    // frenchLanguageCertificate: 'French Language Certificate',
    // frenchLanguageCertificateComment: 'French Language Certificate Comment',
    // otherForeignLanguage: 'Other Foreign Language',
    // otherForeignLanguageCertificate: 'Other Foreign Language Certificate',
    // otherForeignLanguageCertificateComment: 'Other Foreign Language Certificate Comment',
    // travelledInFrance: 'Travelled in France',
    // travelledInFranceComment: 'Travelled in France comment',
    // formerFrenchScholarship: 'Former French scholarship',
    // scholarshipType: 'Scholarship type',
    // year: 'Year',
    // profileComment: 'Profile comment',
    // publicationList: 'Publication list',
    // publicationListComment: 'Publication list Comment',
    // presentCourseLevel: 'Present Course Level',
    // presentCourseYear: 'Present Course Year',
    // presentCourseField: 'Present Course Field',
    // presentCourseSpeciality: 'Present Course Speciality',
    // presentCourseInstitution: 'Present Course Institution',
    presentCourseScholarshipHolder: 'Détentreur d`une bourse d`étude',
    // presentCourseLastTranscript: 'Present Course Last Transcript',
    // presentCourseLastTranscriptComment: 'Present Course Last Transcript Comment',
    resume: 'Curriculum vitae',
    resumeComment: 'Commentaire curriculum vitae',
    lastDegree: 'Dernier diplôme obtenu',
    LastDegreeDateOfGraduation: 'Date d’obtention du dernier diplôme',
    lastDegreeLevel: 'Niveau du dernier diplôme obtenu',
    lastDegreeField: 'Domaine du dernier diplôme obtenu',
    lastDegreeSpeciality: 'Spécialité du dernier diplôme obtenu',
    lastDegreeInstitution: 'Établissement du dernier diplôme obtenu',
    lastDegreeRed: 'Mention “С отличием”',
    lastDegreeCertifiedCopy: 'Copie certifiée du dernier diplôme obtenu',
    lastDegreeCertifiedCopyComment: 'Commentaire',
    lastDegreeTranscript: 'Notes du dernier diplôme obtenu',
    lastDegreeTranscriptComment: 'Commentaire',
    doctorateInRussia: 'Situation actuelle',
    institutionName: 'Nom de l\'établissement',
    institutionCity: 'Ville de l\'établissement',
    doctorateThesisDirectorName: 'Nom du directeur de thèse',
    doctorateThesisDirectorEmail: 'Adresse email du directeur de thèse',
    doctorateScholarship: 'Titulaire d’une bourse d\'études',
    yes: 'Oui',
    no: 'Non',
    presentSituation: 'Situation actuelle',
    presentSituationStudent: 'Étudiant',
    presentSituationProfessional: 'En activité',
    presentSituationJobSeeker: 'En recherche d’emploi',
    professionJobName: 'Profession',
    professionDateOfHiring: 'Date de début d’activité',
    professionEmployerName: 'Nom de l’employeur',
    professionEmployerAddress: 'Adresse de l’employeur',
    studentCardOrJobPermit: 'Carte d\'étudiant ou permis de travail',
    studentCardOrJobPermitComment: 'Commentaire',
    formerScholarship: 'Précisez laquelle',
    frenchLanguageLevel: 'Niveau de langue française',
    frenchLanguageCertificate: 'Certificat de langue française',
    frenchLanguageCertificateComment: 'Commentaire',
    otherForeignLanguage: 'Autre langue étrangère',
    otherForeignLanguageCertificate: 'Certificat de langue étrangère',
    otherForeignLanguageCertificateComment: 'Commentaire',
    travelledInFrance: 'A déjà voyagé en France',
    travelledInFranceComment: 'Commentaire',
    formerFrenchScholarship: 'A déjà obtenu une bourse française',
    scholarshipType: 'Type de bourse d\'études',
    year: 'Année',
    profileComment: 'Commentaire général sur le profil',
    publicationList: 'Liste des publications',
    publicationListComment: 'Commentaire',
    presentCourseLevel: 'Niveau d\'études actuel',
    presentCourseYear: 'Année d\'études actuelle',
    presentCourseField: 'Domaine d\'études actuel',
    presentCourseSpeciality: 'Spécialité d\'études actuelle',
    presentCourseInstitution: 'Établissement d\'études actuel',
    // presentCourseScholarshipHolder: 'Détenteur d’une bourse d\'études',
    presentCourseLastTranscript: 'Dernier bulletin de notes',
    presentCourseLastTranscriptComment: 'Commentaire',
    Lastdegreeinstitut:"Précisez laquelle",
    Lastdegreeinstitut1:"Précisez lequel"
}