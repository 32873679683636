<template>
  <div id="app">
      <component :is="layout" class="page-wrapper">
          <router-view/>
      </component>
  </div>
</template>

<script>
import MainLayout from "./layouts/MainLayout";
import EmptyLayout from "./layouts/EmptyLayout";
import NotAuthLayout from "./layouts/NotAuthLayout";
import {mapGetters} from "vuex"
// import {mapActions, mapGetters} from "vuex"

export default {
  name: 'App',
    computed: {
        ...mapGetters('auth', ['userData']),
        layout() {
            return (this.$route.meta.layout || 'empty') + '-layout'
        }
    },
    components: {
        MainLayout,
        EmptyLayout,
        NotAuthLayout
    },

    created() {
        const currentLang = localStorage.getItem('langKey')
        if (currentLang) {
            this.$i18n.locale = localStorage.getItem('langKey')
        }
    },

    async mounted() {
//         await this.$store.dispatch('getSimpleLists')
//            await this.getAllScholarships()

    },

    watch : {
        deep:true,
//         async userData(){
//             await this.getAllScholarships()
//      }
    },

    methods: {
//      ...mapActions('applications', ['getAllScholarships'])
    },

}
</script>

<style>
@font-face {
    font-family: "Inter";
    src: local("Inter"),
    url(./assets/fonts/Inter-VariableFont_slnt,wght.ttf) format("truetype");
}

html.md-theme-default  {
    background-color: white !important;
}
* {
    font-family: Inter;
    margin: 0;
    padding: 0;
    /*user-select: none;*/
}
</style>
