<template>
    <div class="left-menu">
        <div v-if="!isApplicationEdit || isNotApplicationPage">
            <div
                v-for="(item,i) in menu"
                :key="i"
                class="menu-item"
                :class="{
                    'menu-item_active': item.isActive,
                    'menu-item_disabled': disabledMenuItem(item)
                }"
                @click="selectMenuItem(item, menu)"
            >
                <span>{{ $t(item.name) }}</span>
            </div>
        </div>
        <div v-else>
            <div
                class="back-wrap"
                :class="{'back-disable': !currentScholarshipTypeId}"
                @click="$router.push('/application')"
            >
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.0156 8.01562V9.98438H4.82812L10.4062 15.6094L9 17.0156L0.984375 9L9 0.984375L10.4062 2.39062L4.82812 8.01562H17.0156Z" fill="#666666"/>
                </svg>
                <span>{{ $t('back') }}</span>
            </div>
            <p class="scholarship-name">{{ scholarshipName }} {{ scholarshipYear }}</p>
            <div
                v-for="(item,i) in menuApplicationEdit"
                :key="i"
                class="menu-item edit-menu-item"
                :class="{
                    'menu-item_active': item.isActive,
                }"
                @click="selectMenuItem(item, menuApplicationEdit)"
            >
                <p v-if="statusConfirmedLaureate || item.path !== '/grant-files' || scholarship && scholarship.applicationStatusId >= 21">
                    <span v-html="item.name"></span>
                    <span class="tooltip-wrap">
                        <img v-if="showExclamationIcon && item.path === '/grant-files'" src="@/assets/images/alert.svg" alt="alert" class="exclamation" />
                        <md-tooltip md-direction="right">
                            <span v-html="exclamationText"></span>
                        </md-tooltip>
                    </span>
                </p>
            </div>
        </div>


        <ChangePassword
            v-if="isVisibleChang"
            @close="closeModal"
            @resetPassword="closeModal"
        />
        <ResetPassword
            v-if="isVisibleReset"
            @close="closeModal"
            @send="send"
        />
    </div>
</template>

<script>
import ChangePassword from "../password/ChangePassword";
import ResetPassword from "../password/ResetPassword";
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'left-menu',

    components: {
        ChangePassword,
        ResetPassword,
    },

    data: () => ({
        isVisibleChang: false,
        isVisibleReset: false,
        active: '',
        menu: [
            // {name: 'My Account', path: '/my-account', isActive: false},
            {name: 'identity', path: '/identity', isActive: false},
            {name: 'Candidature', path: '/application', isActive: false},
            // {name: 'Profile', path: '/profile', isActive: false},
            // {name: 'Projects', path: '/projects', isActive: false},
            {name: 'messages', path: '/messages', isActive: false},
            {name: 'changePassword', path: '#', isActive: false}
        ],
        menuApplicationEdit: [
            {name: 'Profil', path: '/profile', isActive: false},
            {name: 'Projets', path: '/projects', isActive: false},
            {name: 'Documents du dossier <br /> de bourse', path: '/grant-files', isActive: false},
        ],
        exclamationText: 'Votre dossier de bourse doit être complété. <br />' +
            'Merci de vérifier la section "Documents <br /> ' +
            'du dossier de bourse" de votre demande, et <br />' +
            'de télécharger les documents manquants ou <br />' +
            ' non conformes',
        grantFiles: {
            id: '',
            campusFranceform: '',
            campusFranceformStatusId: '',
            campusFranceformComment: '',

            embassyform: '',
            embassyformStatusId: '',
            embassyformComment: '',

            passportcopy: '',
            passportcopyStatusId: '',
            passportcopyComment: '',

            admissionletter: '',
            admissionletterStatusId: '',
            admissionletterComment: '',
        },
        hasDeclinedFile: false,
        hasEmptyFile: false,
        done: false
    }),

    computed: {
        ...mapGetters('auth', ['userData']),
        ...mapGetters('applications',['scholarships', 'currentScholarshipTypeId', 'projectData']),
        ...mapGetters(['simpleLists']),
        userName() {
            const user = this.userData
            return user ? user.firstName : ''
        },
        isMaster() {
            return parseInt(this.currentScholarshipTypeId) === 4
        },
        isApplicationEdit() {
            return this.$route.name.includes('project')
                || this.$route.name.includes('profile')
                || this.$route.name.includes('grant-files')
        },
        scholarship() {
            return this.scholarships?.find(el => el.applicationId !== null)
        },
        scholarshipName() {
            let name = ''
            switch (this.scholarship?.scholarshipTypeId || +this.$route.params.id) {
                case 1:
                    name = 'Metchnikov'
                    break;
                case 2:
                    name = 'Ostrogradski'
                    break;
                case 3:
                    name = 'Vernadski'
                    break;
                case 4:
                    name = 'Master'
                    break;
            }
            return name
        },
        scholarshipYear() {
            return this.$moment(this.scholarship?.closingDate).format('YYYY')
        },
        isNotApplicationPage() {
            return this.$route.name.includes('not-application-identity')
        },
        statusConfirmedLaureate() {
            return this.scholarship?.applicationStatusId >= 8
        },
        showExclamationIcon() {
            //The exclamation mark appears if Application status >= “Confirmed laureate”(id = 8) AND at least one grant file is either missing or rejected
            return this.hasDeclinedFile && this.hasEmptyFile && this.scholarship?.applicationStatusId >= 8  || false
        }
    },

    watch: {
        '$route.path': {
            immediate: true,
            handler() {
                this.activeItem()
            }
        },
        scholarship: {
            immediate: true,
            async handler(value) {
                if(this.done) return;
                if (value && value.id) {
                    const data = await this.getApplicationProjects(value.applicationId)
                    const currentData = data[0]
                    for (let field in this.grantFiles) {
                        if (currentData[field] !== "null") {
                            this.grantFiles[field] = currentData[field]
                        }
                    }
                    for(let field in this.grantFiles) {
                        if(field.includes('StatusId') && this.grantFiles[field] === 3) {
                            this.hasDeclinedFile = true
                        }
                        if(!field.includes('StatusId') && !field.includes('Comment') && field !== 'id' && !this.grantFiles[field]) {
                            this.hasEmptyFile = true
                        }
                    }
                    this.done = true;
                }
            }
        }
    },

    methods: {
        // ...mapActions(['getSimpleLists']),
        // ...mapActions('applications',['getAllScholarships']),
        ...mapActions('applications', ['getApplicationProjects']),
        disabledMenuItem(item) {
            if(this.isNotApplicationPage) {
                if(item.name === "application" || item.name === "messages" || item.name === "Candidature" || item.name === "changePassword") {return true} else {return false}
            } else {
                return false
            }
        },
        selectMenuItem(item, menu) {
            if(item.name === 'changePassword') {
                if (!this.userName) return;
                this.isVisibleReset = true
                menu.map(el => {
                    el.isActive = false
                })
                item.isActive = true
            } else if(item.name === 'Projets') {
                // let scholarship =  this.scholarships.find(el => el.applicationId !== null)
                this.$router.push(`${item.path}/${this.scholarship.applicationId}`)
            } else {
                this.$router.push(item.path)
            }
            this.active = item
        },
        activeItem() {
            /* el.path.includes(this.$route.name) for projectS active during project edit */
            if(this.isApplicationEdit) {
                this.menuApplicationEdit.map(el => {
                    if(this.$route.path.includes(el.path) || el.path.includes(this.$route.name)) {
                        el.isActive = true
                    } else {
                        el.isActive = false
                    }
                })
                return;
            }
            this.menu.map(el => {
                if(this.$route.path.includes(el.path) || el.path.includes(this.$route.name)) {
                    el.isActive = true
                } else {
                    el.isActive = false
                }
            })
            if(this.$route.path === '/not-application') {
                this.menu[0].isActive = true
            }
        },
        closeModal() {
            this.isVisibleChang = false;
            this.isVisibleReset = false;
            this.activeItem()
        },
        send() {
            this.isVisibleChang = true;
            this.isVisibleReset = false;
        },
        // getMenuItemName(item) {
        //     if(item.path === '/projects' && !this.isMaster) {
        //         return 'Project'
        //     } else return item.name
        // },
    }

}
</script>

<style lang="scss" scoped>
    .menu-item_hide {
      display: none;
    }
    .left-menu {
        min-width: 305px;
        background: #F5F8FD;
        padding: 50px;
        text-align: left;
        margin-right: 40px;

        .menu-item {
            color: #000000;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 25px;
            cursor: pointer;

            &_active {
                color: #0045FF;
            }

            &_disabled {
                color: #a5a5a5;
                pointer-events: none;
            }
        }

        .change-password {
            overflow-x: hidden;
            overflow-y: auto;
            position: fixed;
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.2);
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 9;
        }

        .reset-password {
            overflow-x: hidden;
            overflow-y: auto;
            position: fixed;
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.2);
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 9;
        }

        .back-wrap {
            display: flex;
            align-items: center;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 25px;
            cursor: pointer;

            svg {
                margin-right: 14px;
            }
        }

        .back-disable {
            pointer-events: none;
            color: #cdd0d9;

            svg path{
                fill: #cdd0d9!important;
            }
        }

        .scholarship-name {
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 25px;
        }

        .edit-menu-item {
            margin-left: 30px;
            display: flex;
        }

        .exclamation {
            margin: 0 0 0 5px;
        }
    }
</style>
<style lang="scss">
.md-tooltip {
    color: #000000!important;
    background-color: #FFFFFF!important;
    border: 1px solid #cdd0d9;
    border-radius: 6px;
    padding: 15px;
    width: 400px!important;
    height: auto!important;
    font-size: 16px;
    line-height: 24px;
}
</style>