// import axios from "axios";
import {mapGetters} from "vuex";

export default {
    namespaced: true,
    computed: {
        ...mapGetters('auth', ['userData']),
    },
    state: () => ({
        profileMetaInfo: null,
        profileData: null,
        projectData: null,
        projectMetaInfo: null,
        scholarships: null,
        simpleLists: null,
        project: null,
        applicationProjects: [],
        scholarshipTypeId: null,
        formattedScholarships: null,
    }),

    mutations: {
        setProfileMetaInfo(state, data) {
            state.profileMetaInfo = data
        },
        setProjectMetaInfo(state, data) {
            state.projectMetaInfo = data
        },
        setProfileData(state, data) {
            state.profileData = data
        },
        setScholarships(state, data) {
            state.scholarships = data;
        },
        setApplicationProjects(state, data) {
            state.applicationProjects = data;
        },
        setProject(state, data) {
            state.project = data;
        },
        setScholarshipTypeId(state, data) {
            state.scholarshipTypeId = data;
        },
        setFormattedScholarships(state, data) {
            state.formattedScholarships = data;
        },
        setProjectData(state, data) {
            state.projectData = data;
        },
        deleteProject(state, id) {
            state.applicationProjects = state.applicationProjects.filter(el => el.id !== id)
        }
    },

    getters: {
        profileMetaInfo: state => state.profileMetaInfo,
        projectMetaInfo: state => state.projectMetaInfo,
        profileData: state => state.profileData,
        scholarships: state => state.scholarships,
        project: state => state.project,
        applicationProjects: state => state.applicationProjects,
        currentScholarshipTypeId: state => state.scholarshipTypeId,
        formattedScholarships: state => state.formattedScholarships,
        projectData: state => state.projectData,
    },

    actions: {
//        async getAccountProfileFieldsMetaInfo({commit}) {
//            return await axios
//                .get(process.env.VUE_APP_API_URL + '/AccountProfileFieldsMetaInfo')
//                .then(({data}) => {
//                    commit('setProfileMetaInfo',data)
//                })
//        },
//        async getAccountProjectFieldsMetaInfo({commit}) {
//            return await axios
//                .get(process.env.VUE_APP_API_URL + '/AccountProjectFieldsMetaInfo')
//                .then(({data}) => {
//                    commit('setProjectMetaInfo',data)
//                })
//        },
//        async getProfileData({commit}) {
//            return await axios
//                .get(process.env.VUE_APP_API_URL + '/AccountProfile')
//                .then(({data}) => {
//                    commit('setProfileData',data)
//                    return data
//                })
//        },
//        async getAllScholarships({commit}) {
//            if (localStorage.getItem('bgfCandidate') ){
//                await axios
//                    .get(process.env.VUE_APP_API_URL + '/AccountScholarship/')
//                    .then(response => {
//                        const scholarships = response.data
//                        commit('setScholarships', scholarships)
//
//                        let scholarship =  scholarships.find(el => el.applicationId !== null)
//                        localStorage.setItem('currentScholarshipTypeId', scholarship?.scholarshipTypeId);
//                        commit('setScholarshipTypeId', scholarship?.scholarshipTypeId)
//
//                        let formattedData = {}
//                        scholarships.map(item => {
//                            if (formattedData[item.scholarshipTypeId]) {
//                                formattedData[item.scholarshipTypeId] = [...formattedData[item.scholarshipTypeId], item]
//                            } else {
//                                formattedData[item.scholarshipTypeId] = [item]
//                            }
//                        })
//                        commit('setFormattedScholarships', formattedData)
//
//                        return scholarships
//                    })
//                    .catch(error => console.log(error));
//            }
//            else{
//                await axios
//                    .get(process.env.VUE_APP_API_URL + '/bgfScholarship/')
//                    .then(response => {
//                        const scholarships = response.data
//                        commit('setScholarships', scholarships)
//
//                        let scholarship =  localStorage.getItem('currentScholarshipTypeId')
//                        localStorage.setItem('currentScholarshipTypeId',scholarship);
//                        commit('setScholarshipTypeId', scholarship)
//
//                        let formattedData = {}
//                        scholarships.map(item => {
//                            if (formattedData[item.scholarshipTypeId]) {
//                                formattedData[item.scholarshipTypeId] = [...formattedData[item.scholarshipTypeId], item]
//                            } else {
//                                formattedData[item.scholarshipTypeId] = [item]
//                            }
//                        })
//                        commit('setFormattedScholarships', formattedData)
//
//                        return scholarships
//                    })
//                    .catch(error => console.log(error));
//
//            }
//        },
//        async getApplicationProjects({commit},id) {
//            if (id) {
//                return await axios
//                    .get(process.env.VUE_APP_API_URL + `/AccountProject/?ApplicationId=${id}`)
//                    .then(response => {
//                        commit('setApplicationProjects', response.data)
//                        if(response.data.length) {
//                            commit('setProjectData', response.data[0])
//                        }
//                        return response.data
//                    })
//                    .catch(error => console.log(error));
//            }
//        },
//
//        async getProject({commit},id) {
//            await axios
//                .get(process.env.VUE_APP_API_URL + `/AccountProject/${id}`)
//                .then(response => {
//                    commit('setProject', response.data)
//                })
//                .catch(error => console.log(error));
//
//        },
//        async saveProject(ctx, data) {
//
//            await  axios
//                .post(process.env.VUE_APP_API_URL + '/AccountProject/Edit/', data, {
//                    headers: {
//                        'Content-Type': 'multipart/form-data'
//                    }
//                })
//                .then(response => {
//                    console.log(response, 'response')
//                })
//        },
//        async deleteProject({commit}, id) {
//            await axios
//                .get(process.env.VUE_APP_API_URL + `/AccountProject/${id}/?Delete=1`)
//                .then(() => {
//                   commit('deleteProject', id)
//                })
//                .catch(error => console.log(error))
//        },

    }
}
