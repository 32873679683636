import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import auth from './auth/index'
import applications from './applications/index'

Vue.use(Vuex);

// axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.xhrFields = { withCredentials: true };
axios.defaults.cache = false;
axios.defaults.async = true;
axios.defaults.crossDomain = true;
axios.defaults.headers = {
    "Access-Control-Allow-Origin": "*",
    "accept": "application/json",
    "Expires": new Date().toISOString()
};

const state = {
    simpleLists: null,
    messages: [],
    unreadMessages: '',
};

const mutations = {
    setSimpleLists(state, data) {
        state.simpleLists = data;
    },
    setMessages(state, data) {
        state.messages = data;
    },
    setUnreadMessages(state, data) {
        state.unreadMessages = data;
    }
};

const actions = {
//    async getSimpleLists({commit}) {
//        await axios
//            .get(process.env.VUE_APP_API_URL + '/bgfsimplelists')
//            .then((result) => {
//                commit('setSimpleLists', result.data)
//            })
//            .catch(error => {
//                console.log(error)
//            })
//    },
//    async getMessages({commit}) {
//        await axios
//            .get(process.env.VUE_APP_API_URL + '/bgfMessage')
//            .then((result) => {
//                const data = result.data
//                commit('setMessages', data)
//
//                let unreadCount = 0;
//                data.forEach(item => {
//                    if(!item.read && item.messageType_ID === 1) {
//                        unreadCount++;
//                    }
//                })
//
//                if(unreadCount) {
//                    commit('setUnreadMessages', unreadCount)
//                }
//
//            })
//            .catch(error => {
//                console.log(error)
//            })
//    },
//
//    async readMessage(_, id) {
//       return await axios.get(`${process.env.VUE_APP_API_URL}/bgfMessage/${id}?read=1`)
//    }
};

const getters = {
    simpleLists: state => state.simpleLists,
    messages: state => state.messages,
    unreadMessages: state => state.unreadMessages,
};

export default new Vuex.Store({
    modules: {
        auth,
        applications,
    },
    state,
    mutations,
    actions,
    getters
})