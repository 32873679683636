<template>
    <div class="md-app-container login-page reset-password">
        <div class="form-logo-wrapper">
<!--            <div class="logo-section" id="img-section">-->
<!--                <img src="@/assets/images/logo.svg" alt="logo">-->
<!--            </div>-->
            <md-card>
                <div class="modal_header">
                    <span class="close" @click="close" >&#10006;</span>
                    <slot name="header"/>
                </div>
                <form action="" @submit.prevent="requestResetPassword">
                    <md-ripple>
                        <md-card-header class="login-header">
                            <div class="md-title">{{ $t('resetPassword') }}</div>
                            <p>{{ $t('title') }}</p>
                        </md-card-header>
                        <md-card-content>
                            <md-field md-inline>
                                <img class="login-input-icon" src="@/assets/images/email.png" alt="Email">

                                <md-input
                                    type="email"
                                    name="email"
                                    v-model="email"
                                    placeholder="Email"
                                    required
                                />
                            </md-field>
                        </md-card-content>

                        <div class="button-wrapper">
                            <md-button type="submit" class="md-raised">
                                <p>{{ $t('btnText') }}</p>
                            </md-button>
                        </div>
                        <Messages :messages="messages"></Messages>
                        <Errors :errors="errors"></Errors>
                    </md-ripple>
                </form>
            </md-card>
        </div>
    </div>
</template>

<script>

import Errors from "@/components/Errors";
import Messages from "@/components/Messages";
import {messages} from "@/mixins/messages";
import {errors} from "@/mixins/errors";
import {printArray} from "@/mixins/printArray"
import {mapActions} from "vuex"

export default {

    data: () => ({
        errors: [],
        email: '',
    }),

    components: {
        Errors,
        Messages
    },

    mixins: [
        messages,
        errors,
        printArray
    ],

    methods: {
        ...mapActions('auth', ['resetPassword']),
        async requestResetPassword() {
            this.clearErrors()
            this.clearMessages()
            await this.resetPassword({email: this.email})
                .then(res => {
                    if(res.success) {
                        this.clearErrors()
                        this.clearMessages()
                        this.send()
                    }
                    else {
                        res && this.setErrors([res.error])
                    }
                })
            .catch(err =>  this.setErrors([err]))
        },

        send() {
            this.$emit('send')
        },
        close() {
            this.$emit('close')
            this.clearErrors()
            this.clearMessages()
            if(this.$route.name === 'forgot-password') {
                this.$router.push('/login')
            }
        }
    }
}

</script>

<style>
.md-field.md-theme-default:after {
    background-color: unset !important;
}
</style>

<style scoped lang="scss">


.md-card.md-theme-default {
    width: 100%;
    margin-top: 20px;
}

.close {
    cursor: pointer;
}

.modal_header {
    padding: 20px 20px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.form-logo-wrapper {
    width: 500px;
    height: 638px;
}

.md-card {
    padding: 20px 50px 120px 50px;
}

.login-page {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo-section {
        padding-left: 68px;
    }
    .login-header {
        padding: 20px 0 50px 18px;

        p {
            text-align: left;
            width: 347px;
            height: 49px;
            left: 50px;
            right: 103px;
            top: 120px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            opacity: 0.5;

        }
    }

    .login-input-icon {
        position: absolute;
        top: 53% !important;
        left: 26px;
        transform: translate(0, -50%);
    }

    .md-title {
        font-weight: 900 !important;
        font-size: 36px;
        line-height: 52px;
        color: #000000;
        text-align: left;
    }

    .button-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .md-raised {
        background: #0045FF !important;
        border-radius: 6px;
        height: 60px;
        width: 220px;

        p {
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 34px;
            text-align: center;
            color: #FFFFFF;
            text-transform: capitalize;
        }
    }

    .md-field {
        border: 1px solid #CDD0D9;
        height: 60px;
        padding-left: 58px;
        font-size: 18px;
        line-height: 32px;
        border-radius: 6px;
        box-sizing: border-box;
    }
}
</style>