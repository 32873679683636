export default {
    masterDocumentsTitle: 'DOCUMENTS À FOURNIR POUR LE DÉPÔT DE CANDIDATURE',
    masterDocumentsSubTitle: '<b>Remarque : </b> une attention particulière est portée à la qualité des traductions' +
        ' des documents officiels fournis par les candidats. <b>Tout faux ou traduction incorrecte disqualifie la candidature.</b>',
    masterDocumentsText1: '<b>La liste des établissements choisis dans le dossier personnel Campus France</b> (informations sur la procédure' +
        ' Campus France : <a href="https://www.russie.campusfrance.org/ru" target="_blank">www.russie.campusfrance.org</a>), et, <b>dès que possible, la lettre d’admission ou de préadmission de l’université .</b>' +
        ' En cas d\'évolution de votre dossier, tenir informé le service des bourses.',
    masterDocumentsText1a: 'La lettre d’admission ou de préadmission doit provenir d’une <b>autorité de l’administration</b> de l’établissement en France.' +
        ' Une lettre signée par un directeur de parcours n’est pas suffisante.',
    masterDocumentsText1b: 'Si vous n\'avez pas encore une lettre d\'admission ou de préadmission, ' +
        'ou si vous ne pouvez pas en obtenir avant la date limite, une <b> capture d\'écran des voeux d\'établissements </b> via la procédure Campus France est suffisante.',
    masterDocumentsText1c: 'Les étudiants déjà acceptés dans le cadre d’un échange entre établissements doivent fournir le document officiel indiquant leur admission. ' +
        'Dans le cadre d’un accord de coopération simple, l’étudiant fournit une <b>copie de l’accord de coopération. </b> Dans le cadre d’un double-diplôme, ' +
        'une <b>copie de l’accord du double-diplôme </b>correspondant est nécessaire.',
    masterDocumentsText2: '<b>Attestation de niveau de langue (niveau minimum requis pour intégrer la formation visée)</b>',
    masterDocumentsText2a: 'Le document doit attester du niveau de langue dans laquelle la formation aura lieu (français ou anglais).',
    masterDocumentsText2b: 'Pour la langue française les documents prouvant le niveau de langue sont : DELF/DALF, TEF, TCF ou les diplômes de la CCIP.',
    masterDocumentsText2c: 'Pour la langue anglaise : IELTS, TOEFL, TOEIC ou BULATS.',
    masterDocumentsText2d: 'Pour les candidats issus de filières linguistiques (dont la langue française est la première langue), un relevé de notes suffit.',
    masterDocumentsText2e: 'Pour les étudiants déjà acceptés en échange dans le cadre d’un accord de coopération ou d’un double-diplôme, une attestation du professeur référent pour le programme suffit.',
    masterDocumentsText3: '<b>Une ou deux lettres de recommandation (en français de préférence ou en anglais)</b>',
    masterDocumentsText3a: 'La lettre de recommandation peut provenir de l’université, de l’employeur du candidat ou encore d’une personne pertinente pour recommander le candidat.',
    masterDocumentsText3b: 'Il est conseillé de fournir une lettre de recommandation de la part d’une <b> personne pertinente </b> qui connait bien le candidat' +
        ' et qui peut expliquer en détail les qualités et la manière de travailler ou d’étudier du candidat.',
    masterDocumentsText3c: 'Un article dédié à la lettre de recommandation est en ligne sur notre blog <a href="https://www.bgfrussie.ru/blog?q=2">ici</a>.',
    masterDocumentsText4: '<b>Curriculum vitae</b>',
    masterDocumentsText4a: 'Les candidats doivent porter une attention particulière à la clarté du CV et à l’organisation pertinente des informations que la personne veut mettre en avant.',
    masterDocumentsText5: '<b>Lettre de motivation</b>',
    masterDocumentsText5a: 'Une attention particulière doit être portée à ce document qui, dans une limite <b>d\'une page, </b> doit expliquer clairement les motivations du candidat.',
    masterDocumentsText5b: 'La <b>forme est libre</b> mais des <b>informations essentielles</b> quant au parcours, au choix de formation en France et aux projets futurs du candidat doivent être présentes.',
    //masterDocumentsText5c: 'Des articles sur la manière de rédiger la lettre de motivation sont en ligne sur notre <a href="http://bgf2020.atalan.net/blog?q=2?lang=fr" target="_blank">blog</a>.',
    masterDocumentsText5d: 'Merci de n’écrire qu’une seule lettre de motivation pour l’ensemble de vos vœux de formation.',
    masterDocumentsText6: '<b>Copie et traduction (en français ou anglais) certifiées par un notaire ou l’université du diplôme russe le plus élevé ou des derniers relevés de notes.</b>',
    masterDocumentsText6a: 'La <b>certification des documents par une personne agréée est obligatoire.</b> Les professeurs de langue non agréés ne sont pas autorisés à fournir des traductions.',
    masterDocumentsText6b: 'Pour les étudiants qui n’obtiendront leur diplôme qu’en fin d’année académique, les <b>relevés de note complets</b> doivent obligatoirement être fournis et traduits.',
    masterDocumentsText6c: 'Les candidats disposant de diplômes délivrés en russe et en anglais sont dispensés de les faire traduire.',
    masterDocumentsText7: '<b>Copie de la carte étudiant ou du certificat de travail si vous n’êtes pas étudiant.</b>',
    masterDocumentsText7a: 'Il est inutile de faire traduire ces documents',
    masterDocumentsSubText: 'N.B : Pour des raisons de confort de lecture, nous vous prions de <b>ne jamais joindre de documents manuscrits</b> à votre dossier.' +
        ' Il est préférable de transmettre des documents dactylographiés, en formats .word, .pdf ou .jpeg',
    formMaster: 'Le <a href="/forms/Formulaire_Candidature_Master_2024.xlsx">Formulaire de candidature</a> à compléter et renvoyer au format excel. Le formulaire peut être complété en anglais.',
    masterDocumentsForCandidate: '<b>Pour les personnes étudiant déjà dans une université en France :</b>',
    masterDocumentsForCandidate1: 'Une copie du diplôme russe devra être fournie ainsi que les résultats déjà obtenus en France à la date de clôture des candidatures.',
    masterDocumentsForCandidate2: 'Une lettre administrative devra être fournie, indiquant dans quel parcours le candidat souhaite s’inscrire pour l’année académique suivante.',
    masterDocumentsForCandidate3: 'Les candidats étudiants déjà en langue française en France sont dispensés de fournir une attestation de niveau de langue.',
	masterDocumentsForCandidate4: 'L’étudiant doit également fournir, comme les autres candidats, les autres documents demandés (lettre de motication, CV, etc.).',
}