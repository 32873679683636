<template>
    <div>
        <NavBar/>
        <div
            class="main-wrapper"
            :class="{'home-main_wrapper': isHomePage}"
        >
            <LeftMenu v-if="!isHomePage" />
            <router-view/>
        </div>
        <footer-section />
    </div>
</template>

<script>
import LeftMenu from "../components/app/NotAuthLeftMenu";
import NavBar from "../components/app/NavBar";
import FooterSection from "../components/app/FooterSection";

export default {
    name: 'notAuth-layout',
    components: {
        FooterSection,
        NavBar,
        LeftMenu,
    },
    computed: {
        isHomePage() {
            return this.$route.name.includes('Home')
        },
    }
}
</script>

<style lang="scss" scoped>
.main-wrapper {
    display: flex;
    align-items: flex-start;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;

    @media(max-width: 1440px) {
        width: 90%;
    }

    @media(max-width: 768px) {
        flex-direction: column;
    }
}
.home-main_wrapper {
    width: 100%;
}
</style>