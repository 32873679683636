export default {
    // hasAccountModalTitle1: 'У вас нет аккаунта?',
    // hasAccountModalTitle2: 'У вас уже есть аккаунт?',
    // existingUserText: 'Аккаунт с этим почтовым адресом уже сушествует. ' +
    //     'Войдите в вашу учётную запись или используйте  другой адрес',
    // hasApplication: 'У вас уже есть заявка на рассмотрении, и вы не можете подать заявку на другую',
    hasAccountModalTitle1: 'Vous n’avez pas de compte',
    hasAccountModalTitle2: 'Vous avez déjà un compte',
    existingUserText: 'Un compte existe déjà avec cet Email et ce nom. ' +
        'Merci de vous connecter pour déposer votre candidature',
    hasApplication: 'Vous avez déjà une demande en cours et ne pouvez pas effectuer une nouvelle demande',
}