<template>
    <div v-if="!isFaqPage && !isContactsPage && !isBlogPage" class="left-menu_wrap">
        <div class="left-menu">
            <div v-if="!registrationForms">
                <p class="menu-title">{{ $t('scholarshipsTitle') }}</p>
                <div
                    v-for="(item,i) in menu"
                    :key="i"
                    class="menu-item"
                    :class="{'menu-item_active': item.isActive, 'disabledClass':item.isDisabled}"
                    @click="selectMenuItem(item)"
                >
                  <p >
                    {{ $t(item.name) }}
                  </p>
                </div>

                <p class="menu-title">{{ $t('partnersScholarshipsTitle') }}</p>
                <div
                    v-for="(item,i) in menuPartners"
                    :key="i + 10"
                    class="menu-item"
                    :class="{'menu-item_active': item.isActive,'disabledClass':item.isDisabled}"
                    @click="selectMenuItem(item)"
                >
                    {{ $t(item.name) }}
                </div>
            </div>

            <div
                v-else
                class="registration-forms"
            >
                <router-link
                    :to="{
                    name: 'registration',
                    params: {
                        id: $route.params.id,
                        studentId: $route.params.studentId,
                        profileId: $route.params.profileId,
                    }
                }"
                    class="form-item"
                    :class="{'form-item_active': isIdentityForm}"
                >
                    <div class="number">1</div>
                    {{ $t('identity') }}
                </router-link>
                <router-link
                    :to="{
                    name: 'student-profile',
                    params: {
                        id: $route.params.id,
                        studentId: $route.params.studentId,
                        profileId: $route.params.profileId,
                    }
                }"
                    class="form-item"
                    :class="{'form-item_active': isProfileForm}"
                >
                    <div class="number">2</div>
                    {{ $t('profile') }}
                </router-link>
                <router-link
                    :to="{
                    name: 'student-project',
                    params: {
                        id: $route.params.id,
                        studentId: $route.params.studentId,
                        profileId: $route.params.profileId,
                    }
                }"
                    class="form-item"
                    :class="{'form-item_active': isProjectForm}"
                >
                    <div class="number">3</div>
                    {{ $t('project') }}
                </router-link>
            </div>

        </div>
        <!--<apply-button
            v-if="!registrationForms && !isEdhecScholarshipPage && !isCufPage"
            class="left-menu mobile-apply"
        />-->
    </div>
</template>

<script>
import { mapGetters} from "vuex";
//import ApplyButton from "@/components/apply-button";

export default {
    name: 'left-menu',

    data: () => ({
        active: '',
        menu: [
            {id: 4, name: 'master', path: '/scholarships/Master', isActive: false},
            {id: 2, name: 'ostrogradski', path: '/scholarships/Ostrogradski', isActive: false},
            {id: 3, name: 'vernadski', path: '/scholarships/Vernadski', isActive: false,isDisabled:true},
            {id: 1, name: 'metchnikov', path: '/scholarships/Metchnikov', isActive: false},
            // {id: 5, name: 'scholarshipCUF', path: '/scholarships/CUF', isActive: false},
        ],
        menuPartners: [
            {id: 6, name: 'scholarshipAudencia', path: '/scholarships/audencia', isActive: false, isDisabled: true},
            {id: 7, name: 'scholarshipEdhec', path: '/scholarships/Edhec', isActive: false, isDisabled: true},
        ],
    }),

    components: {
        //ApplyButton,
    },

    computed: {
        ...mapGetters('auth', ['userData']),
        ...mapGetters('applications',['scholarships']),
        ...mapGetters(['simpleLists']),
        userName() {
            const user = this.userData
            return user ? user.firstName : ''
        },
        registrationForms() {
            return this.$route.name.includes('registration')
                    || this.$route.name.includes('profile')
                    || this.$route.name.includes('project')
        },
        isIdentityForm() {
            return this.$route.name.includes('registration') || this.$route.params.studentId != 0
        },
        isProfileForm() {
            return this.$route.name.includes('profile') || this.$route.params.studentId != 0
        },
        isProjectForm() {
            return this.$route.name.includes('project') || parseInt(this.$route.params.profileId)
        },
        isFaqPage() {
            return this.$route.name.includes('faq')
        },
        isContactsPage() {
            return this.$route.name.includes('contacts')
        },
        isBlogPage() {
            return this.$route.name.includes('blog')
        },
        isEdhecScholarshipPage() {
            return this.$route.name.includes('edhec')
        },
        isCufPage() {
            return this.$route.name.includes('CUF')
        },
    },

    watch: {
        '$route.path': {
            immediate: true,
            handler() {
                this.activeItem(this.menu);
                this.activeItem(this.menuPartners);
            }
        },
    },
    async mounted() {
        // await this.getSimpleLists();
        // await this.getAllScholarships();
    },

    methods: {
        // ...mapActions(['getSimpleLists']),
        // ...mapActions('applications',['getAllScholarships']),
        selectMenuItem(item) {
            this.$router.push(item.path)
            this.active = item
        },
        activeItem(menu) {
            menu.map(el => {
                if (this.$route.path.includes(el.path)) {
                    el.isActive = true
                } else {
                    el.isActive = false
                }
            })
        }
    }

}
</script>

<style lang="scss" scoped>
.mobile-apply {
    display: none;
    @media (max-width: 1440px) {
        display: flex;
    }
    @media(max-width: 768px) {
        height: 48px;
        padding: 0!important;
        margin: 0!important;
        background-color: transparent!important;
        position: fixed;
        left: 50%;
        bottom: 20px;
        transform: translate(-50%, -50%);
    }
}
.left-menu {
    min-width: 335px;
    max-width: 350px;
    background: #F5F8FD;
    padding: 35px;
    text-align: left;
    margin-right: 40px;
    margin-bottom: 30px;

    @media(max-width: 480px) {
        margin-right: 0;
        min-width: auto;
    }

    &_wrap {
        @media(max-width: 480px) {
            display: block;
            margin: auto;
        }
    }

    .menu-title {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px;
    }

    .menu-item {
        color: #000000;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px;
        margin-left: 25px;
        cursor: pointer;

        &_active {
            color: #0045FF;
        }
    }

    .registration-forms {
        display: flex;
        flex-direction: column;

        a {
            text-decoration: none;
            color: #abadb1;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
        }

        .number {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #abc2fe;
            color: #FFFFFF;
            margin-right: 14px;
        }

        .form-item {
            display: flex;
            align-items: center;
            margin-bottom: 25px;
            pointer-events: none;

            &_active {
                color: black;
                pointer-events: unset;

                .number {
                    background-color: #0045FF;
                }
            }

        }

    }
}
</style>

<style lang="scss">
.left-menu {
    .md-theme-default a:not(.md-button):hover {
        color: black;
    }
}
.disabledClass {
  pointer-events: none;
  color: #abadb1 !important;
}
</style>