export default {
    masterDocumentsTitle: 'ДОКУМЕНТЫ ДЛЯ ПОДАЧИ ЗАЯВКИ',
    masterDocumentsSubTitle: '<b>Важное замечание:</b> особое внимание будет уделяться качеству перевода официальных' +
        ' документов предоставляемых кандидатами.<b> Любые фальшивые или некорректные переводы аннулируют поданную заявку. </b>',
    masterDocumentsText1: '<b>Список с выбранными ВУЗами из личного досье Кампюс Франс </b> (информация о процедуре Кампюс Франс:' +
        ' <a href="https://www.russie.campusfrance.org/ru" target="_blank">www.russie.campusfrance.org</a>),<b> и, как можно скорее, письмо о зачислении или о предварительном зачислении в университет. </b>' +
        'При возникновении новых элементов в досье (например, получение письма о зачислении), необходимо сообщить об этом в отдел стипендий.',
    masterDocumentsText1a: 'Письмо о зачислении или о предварительном зачислении должно быть выдано <b> уполномоченным административным органом </b>' +
        ' французского учреждения. Письмо, подписанное директором программы, не является достаточным.',
    masterDocumentsText1b: 'Список выбранных через процедуру Кампюс Франс ВУЗов может быть предоставлен в виде снимка с экрана (скриншота).',
    masterDocumentsText1c: 'Студенты уже зачисленные в рамках межвузовского обмена должны предоставить официальный документ о зачислении.' +
        ' В случае простого соглашения о сотрудничестве, необходимо предоставить <b>копию соглашения о сотрудничестве.</b> В случае двойного диплома – копию соглашения одвойном дипломе.',
    masterDocumentsText2: '<b>Документ, подтверждающий уровень владения языком (необходимый минимальный уровень для выбранной программы обучения)</b>',
    masterDocumentsText2a: 'Документ должен свидетельствовать об уровне владения тем языком, на котором планируется вестись обучение (французский или английский).',
    masterDocumentsText2b: 'Для подтверждения уровня владения французским языком допускаются следующие документы: DELF/DALF, TEF, TCF или дипломы CCIP.',
    masterDocumentsText2c: 'Для подтверждения уровня владения английским языком: IELTS, TOEFL, TOEIC или BULATS.',
    masterDocumentsText2d: 'Для кандидатов, являющихся студентами или обладателями диплома лингвистических специальностей ' +
        '(где французский язык является первым иностранным), достаточно выписки с оценками из зачетной ведомости.',
    masterDocumentsText2e: 'Для студентов, уже зачисленных по программе обмена в рамках соглашения о сотрудничестве или о двойном дипломе, ' +
        'достаточно письменного свидетельства (справки) от ответственного за программу преподавателя.',
    masterDocumentsText3: '<b>Рекомендательные письма (одно или два, желательно на французском языке, можно на английском)</b>',
    masterDocumentsText3a: 'Рекомендательное письмо может быть написано университетом, работодателем кандидата или каким-либо другим человеком, подходящим для данного рода рекомендаций.',
    masterDocumentsText3b: 'Желательно, чтобы рекомендательное письмо происходило от человека, который хорошо знает кандидата и может подробно рассказать о его достоинствах, манере работать и учиться.',
    masterDocumentsText3c: '',
    masterDocumentsText4: '<b>Резюме</b>',
    masterDocumentsText4a: 'Кандидаты должны уделить особое внимание четкости подачи информации в резюме и его грамотному построению, в соответствии с тем,' +
        ' какие сведения кандидат считает важным выдвинуть на передний план.',
    masterDocumentsText5: '<b>Мотивационное письмо</b>',
    masterDocumentsText5a: 'С особым вниманием следует отнестись к этому очень важному документу, который не должен превышать <b>одной страницы</b> и в котором кандидат должен четко и ясно изложить свою мотивацию.',
    masterDocumentsText5b: 'Письмо может быть написано в <b>свободной форме,</b> но должно содержать <b>основную информацию</b> о предыдущем академическом и профессиональном опыте,' +
        ' а также о выборе образовательной программы во Франции и о будущих планах.',
    //masterDocumentsText5c: 'Напишите только одно мотивационное письмо для всех Ваших желаний. Когда Вы вводите своих желания обучения на сайте, Вам придётся везде писать одно и то же мотивационное письмо.',
    masterDocumentsText5d: 'Пожалуйста, пишите только одно сопроводительное письмо для всех Ваших пожеланий по обучению.',
    masterDocumentsText6: '<b>Копия российского диплома самого высокого уровня и его перевод (на французском или на русском языке), заверенный нотариусом или университетом, или выписка из зачётной ведомости</b>',
    masterDocumentsText6a: '<b>Документы должны</b> быть в обязательном порядке заверены <b>уполномоченным лицом.</b> Преподаватели иностранных языков без соответствующей аккредитации, не могут выполнять перевод документов.',
    masterDocumentsText6b: 'Студенты, которые планируют получить диплом только в конце текущего учебного года, должны в обязательном' +
        ' порядке предоставить полную <b>выписку из зачётной</b> ведомости, переведенную на французский язык.',
    masterDocumentsText6c: 'Кандидаты, которые обладают двуязычными англо-русскими дипломами, освобождаются от их перевода на французский язык.',
    masterDocumentsText7: '<b>Копия студенческого билета или справка с места работы для кандидатов, которые не являются студентами.</b>',
    masterDocumentsText7a: 'Данные документы переводить не нужно.',
    masterDocumentsSubText: '<b>N.B: </b> Убедительная просьба <b>не присылать рукописные документы,</b> неудобные для чтения, а предоставлять их в отпечатанном виде в формате word, pdf или jpeg.',
    formMaster: 'Форма <a href="/forms/Formulaire_Candidature_Master_2024.xlsx">Formulaire de candidature</a> заполнена и отправлена в формате excel. Форма может быть заполнена на английском языке.',
    masterDocumentsForCandidate: '<b>Для кандидатов, которые уже являются студентами французских ВУЗов:</b>',
    masterDocumentsForCandidate1: 'Необходимо предоставить копию российского диплома, а также результаты учебы во французском ВУЗе, полученные на момент окончания приёма заявок на стипендию.',
    masterDocumentsForCandidate2: 'Необходимо предоставить письмо, с указанием факультета (программы), на который кандидат планирует записываться на следующий учебный год. Если кандидат уже подал заявку на запись,' +
        ' письмо должно происходить от администрации университета, в противном случае, его составляет и подписывает сам кандидат (в свободной форме).',
    masterDocumentsForCandidate3: 'Кандидаты, уже обучающиеся на французском языке, освобождаются от необходимости предоставлять документ, подтверждающий уровень владения языком.',
    masterDocumentsForCandidate4: 'Как и другие кандидаты, студенты должны предоставить и другие запрашиваемые документы (сопроводительное письмо, резюме и т.д.).',
}