<template>
    <div class="header_wrapper">
        <div class="header">
            <button v-if="userName" class="arrow-btn navbar-button-wrap">
                <img src="@/assets/images/user1.svg" class="user-img" alt="user">
                <p class="user-name">{{ userName }}</p>
                <button class="drop-down" @blur="close">
                    <div class="arrow-btn">
                        <img
                            :class="{'arrow-down': isOpen}"
                            style="margin-left: 15px"
                            src="@/assets/images/arrow-down.png"
                            alt="arrow"
                            @click="isOpen = !isOpen"
                        >
                    </div>

                    <ul
                        v-if="isOpen"
                        class="drop-down_content"
                    >
                        <li @click="logoutUser">Logout</li>
                    </ul>
                </button>

            </button>

        </div>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'chat-nav-bar',
    data: () => ({
        isOpen: false,
    }),

    computed: {
        ...mapGetters('auth', ['userData']),
        userName() {
            const user = this.userData
            return user ? user.firstName : ''
        },
    },

    methods: {
        ...mapActions('auth', ['logout']),

        async logoutUser() {
            const success = await this.logout()
            if (success) {
                this.$router.push('/')
            }
        },
        close() {
            this.isOpen = false
        },

    }

}
</script>

<style lang="scss" scoped>
.header {

    &_wrapper {
        background: #FFFFFF;
        height: 75px;
        padding: 0 30px 0 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .user-img {
        width: 36px;
        height: 36px;
        margin: 0 15px;
    }
    .user-name {
        font-weight: normal;
        font-size: 18px;
        line-height: 32px;
    }
    .arrow-btn {
        border: none;
        outline: none;
        background-color: inherit;
        position: relative;

        img {
            cursor: pointer;
        }
    }

    .arrow-down {
        transform: rotate(180deg);
    }

    .drop-down {
        border: none;
        outline: none;
        background-color: transparent;
        position: relative;

        &_content {
            width: 240px;
            position: absolute;
            border-radius: 0 6px 6px 0;
            border: 1px solid #cdd0d9;
            right: -5px;
            top: 25px;
            list-style: none;
        }

        li {
            display: flex;
            align-items: center;
            height: 54px;
            padding: 0 20px;
            background-color: #FFFFFF;
            border-radius: 0 6px 6px 0;
            color: #000000;
            font-size: 16px;
            line-height: 24px;
            transition: .3s;
            cursor: pointer;

            &:hover {
                background-color: #0045FF;
                color: #FFFFFF;
            }
        }
    }

    .navbar-button-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 75px;

        &:hover {
            cursor: pointer;
        }
    }
}
</style>