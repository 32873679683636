export default {
    sliderTitle: 'Стипендиальная программа французского правительства (мастер) - Приём заявок откроется в конце ноября',
    more: 'Подробнее',
    homeTitle: 'Стипендии французского Правительства при Посольстве',
    scholarshipMetchnikov: 'СТИПЕНДИАЛЬНАЯ ПРОГРАММА МЕТЧНИКОВА',
    scholarshipOstrogradski: 'СТИПЕНДИАЛЬНАЯ ПРОГРАММА ОСТРОГРАДСКОГО',
    scholarshipVernadski: 'СТИПЕНДИАЛЬНАЯ ПРОГРАММА ВЕРНАДСКОГО',
    scholarshipMaster: 'СТИПЕНДИАЛЬНАЯ ПРОГРАММА ФРАНЦУЗСКОГО ПРАВИТЕЛЬСТВА',
    partnerScholarshipsTitle: 'Стипендии партнёров',
    scholarshipHenri: 'СТИПЕНДИАЛЬНАЯ ПРОГРАММА АНРИ ПУАНКАРЕ',
    submit: 'Подать заявку',
    questions: 'Часто задаваемые вопросы',
    logout: 'Logout'
}