import axios from "axios";

export default {
    namespaced: true,

    state: () => ({
        user: null,
        loginErrors: null,
        accountData: null
    }),

    mutations: {
        setUserData(state, userData) {
            state.user = userData;
            localStorage.setItem('bgfCandidate', JSON.stringify(userData));
            axios.defaults.headers.Authorization = `${userData.token}`;
        },

        clearUserData(state) {
            localStorage.removeItem('bgfCandidate');
            localStorage.removeItem('bgfNewData');
            localStorage.removeItem('currentScholarshipTypeId');
            state.user = null
            axios.defaults.headers.Authorization = ''
        },

        setLoginError(state, data) {
            state.loginErrors = data;
        },

        setUser(state, userData) {
            state.user = userData
            axios.defaults.headers.Authorization = userData.token
        },

        setAccountData(state, data) {
            state.accountData = data
        },
    },

    getters: {
        userData: state => state.user,
        loginErrors: state => state.loginErrors,
        accountData: state => state.accountData,
    },

    actions: {
//        login({commit}, payload) {
//            return axios
//                .post(process.env.VUE_APP_API_URL + '/bgflogin/token', payload)
//                .then(({data}) => {
//                    if (data.success) {
//                        commit('setUserData', data)
//                    } else {
//                        commit('setLoginError', data.error)
//                    }
//                    return data.success
//                })
//        },
        getUser({commit}) {
            let userData =  JSON.parse(localStorage.getItem('bgfCandidate'))
            commit('setUser', userData)
       },

//        async getMyAccountData({commit}) {
//            const userData =  JSON.parse(localStorage.getItem('bgfCandidate'));
//            axios.defaults.headers.Authorization = userData.token;
//            return await axios
//                .get(process.env.VUE_APP_API_URL + '/Account/')
//                .then(({data}) => {
//                    commit('setAccountData',data)
//                })
//        },

//        logout(ctx) {
//            let userData = localStorage.getItem('bgfCandidate')
//            if (userData) {
//                  return axios
//                        .get(process.env.VUE_APP_API_URL + '/bgflogout')
//                        .then(({data}) => {
//                            ctx.rootState.messages = []
//                            ctx.rootState.unreadMessages = ''
//                            ctx.commit('clearUserData')
//                            return data.success
//                        })
//                        .catch(({error}) => {
//                            console.log('logout', error)
//                        })
//            }
//        },


//        resetPassword(ctx,data) {
//            return axios
//                .post(process.env.VUE_APP_API_URL + '/AccountRestorepassword/SendAccessCode', data)
//                .then(data => {
//                    localStorage.setItem(data.data.email, data.data.clientkey);
//                    return data.data
//                })
//                .catch(({error}) => {
//                    console.log('resetPassword', error)
//                })
//        },
//
//        changePassword(ctx,data) {
//            return axios
//                .post(process.env.VUE_APP_API_URL + '/AccountRestorepassword/SavePassword', data)
//                .then(data => {
//                    return data.data
//                })
//                .catch(({error}) => {
//                    console.log('changePassword', error)
//                })
//        },
//
//        async registration(ctx,data) {
//            return await axios
//                .post(process.env.VUE_APP_API_URL + '/Account/Edit/', data)
//                .then(({data}) => {
//                    return data
//                })
//                .catch(e => {
//                    return e.response.data
//                })
//        }

    }
}