export default {
    nameOfInstitution: 'Établissement d’accueil en France',
    laboratory: 'Laboratoire',
    unitNumber: 'Numéro de l’unité',
    projectName: 'Nom du projet',
    scientificSpeciality: 'Discipline',
    projectDescription: 'Description du projet',
    projectDescriptionComment: 'Commentaire',
    invitationOrAdmissionLetter: 'Lettre d’admission ou de préadmission dans un établissement ' +
        'd’enseignement supérieur français',
    invitationOrAdmissionLetterComment: 'Commentaire',
    endorsementLetter: 'Lettres de recommandation',
    endorsementLetterComment: 'Commentaire',
    projectDoctorateThesisDirectorName: 'Nom du directeur de thèse en France',
    projectDoctorateThesisDirectorEmail: 'Adresse email du directeur de thèse en France',
    conventionProcessLetter: 'Convention de doctorat en cotutelle de thèse ou courrier des deux directeurs de thèse confirmant qu’elle est en cours d’élaboration ',
    conventionProcessLetterComment: 'Commentaire',
    projectComment: 'Commentaire général sur le projet',
    institutionCity: 'Ville de l\'établissement',
    OtherSpeciality: 'Autre discipline',
    institutionContactName: 'Nom de votre contact en France',
    institutionContactEmail: 'Adresse email de votre contact',
    courseWishLevel: 'Niveau de la formation souhaitée',
    courseWishYear: 'Année de la formation souhaitée',
    courseWishField: 'Domaine de la formation souhaitée',
    courseWishName: 'Nom de la formation souhaitée',
    otherCity: 'Précisez laquelle',
    otherCity2: 'Précisez lequel',
    courseWishWorkingLanguage: 'Langue de travail de la formation souhaitée',
    courseWishMotivationLetter: 'Lettre de motivation',
    courseWishMotivationLetterComment: 'Commentaire',
    courseWishAdmissionLetter: 'Lettre d’admission dans la formation souhaitée',
    courseWishAdmissionLetterComment: 'Commentaire',
    courseWishProgram: 'Type de mobilité ou d’accord de la formation souhaitée',
    durationOfStayInFrance: 'Durée du séjour en France (en nombre de mois)',
    popupText: 'Ваша заявка сохраняется. Это может занять несколько секунд. Пожалуйста, не обновляйте и не закрывайте страницу, чтобы не потерять ваши данные.',
    message:'Ваша заявка была аннулирована, но ваша учетная запись сохранена. Для восстановления вашей заявки кликните на “Подать заявку” и введите ваши Email и пароль.',
    message1:'Votre candidature a été annulée, en revanche votre identifiant a bien été sauvegardé. Pour\n' +
        '        reprendre votre candidature il vous faut cliquer sur « déposer ma candidature » et entrer votre Email et votre\n' +
        '        Mot de passe.',
    LettreDeRecommandation: 'Lettre de motivation'

}