<template>
  <div>
      <notifications
          group="success-notify"
          class="success-notify"
      />
      <div v-if="!isChatPage">
          <NavBar/>
          <div class="main-wrapper">
              <LeftMenu />
              <router-view/>
          </div>
      </div>
      <div v-else class="chat-page_wrapper">
          <ChatLeftMenu />
          <div class="chat-main-wrapper">
              <ChatNavBar />
              <router-view />
          </div>
      </div>

  </div>
</template>

<script>
import LeftMenu from "../components/app/LeftMenu";
import NavBar from "../components/app/NavBar";
import ChatLeftMenu from "../components/app/ChatLeftMenu";
import ChatNavBar from "../components/app/ChatNavBar";

export default {
  name: 'main-layout',
  components: {
      NavBar,
      LeftMenu,
      ChatLeftMenu,
      ChatNavBar,
  },
    computed: {
      isChatPage() {
          return this.$route.name.includes('chat')
      }
    }
}
</script>

<style lang="scss" scoped>
.success-notify {
    top: 110px!important;

    .notification-title {
        font-size: 16px!important;
    }
}

.vue-notification.success {
    height: 50px;
    display: flex;
    align-items: center;
}
.main-wrapper {
    display: flex;
    align-items: flex-start;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;

    @media(max-width: 1440px) {
        width: 90%;
    }

    @media(max-width: 768px) {
        width: 100%;
    }
}
.chat-page_wrapper {
    display: flex;
    align-items: flex-start;
    width: 100%;
}
.chat-main-wrapper {
    width: calc(100% - 300px);
}
</style>