export default {
    blogQuestionsTitle: 'Articles récents',
    blogTitle1: 'Qu’est-ce qu’une lettre de motivation ? Que dois-je y écrire ?',
    blogTitle2: 'Qu’est-ce qu’une lettre de recommandation ? Qui doit la rédiger ?',
    blogTitle3: 'Bourse co-financée Henri Poincare ',
    blogAnswer1: 'Une lettre de motivation est une lettre dans laquelle vous exposez par écrit ' +
        'aux jurés les raisons qui vous incitent à postuler à cette bourse, et qui font de vous un candidat qualifié.' +
        ' Elle est une «vitrine» à travers laquelle les évaluateurs se feront une idée de votre caractère,' +
        ' de vos aptitudes et de vos talents relationnels. Soignez donc la présentation, l’orthographe et votre raisonnement !',
    blogAnswer11: 'Une lettre de motivation doit être rédigée en suivant un certain nombre de codes. ' +
        'N’hésitez pas à vous renseigner autour de vous et à vous faire corriger par des francophones ! (quelques <a>conseils</a>, à titre indicatif)',
    blogAnswer2: 'Une lettre de recommandation est une missive dans laquelle un référent académique ou professionnel fait part des' +
        ' raisons qui l’incitent à penser que vous êtes une personne très qualifiée à recevoir un soutien financier de notre part.',
    blogAnswer22: 'Concrètement, elle doit comporter : la date, l’identité de votre référent, ses fonctions, la manière dont' +
        ' vous êtes entrés en contact, les projets que vous avez menés ensemble, les raisons qui font de vous un excellent candidat,' +
        ' la signature du référent, le tampon de l’université ou de l’employeur.',
    blogAnswer3: '© Laboratoire d Histoire des sciences et de philosophie - Archives Henri Poincaré (CNRS, université de Lorraine) ',
    blogAnswer33: '',
    source: 'source',
    shortBlogTitle1: 'Qu’est-ce qu’une lettre de motivation ?',
    shortBlogTitle2: 'Qu’est-ce qu’une lettre de recommandation ?',
    shortBlogTitle3: 'Les universités avec le meilleur taux de réussite en master en 2021',
    shortBlogDescription1: 'Une lettre de motivation est une lettre dans laquelle vous exposez par écrit...',
    shortBlogDescription2: 'Une lettre de recommandation est une missive dans laquelle un référent académique...',
    shortBlogDescription3: '71 universités françaises ont été classées...',
    blogDate: '12 juin 2021',
    blogtext:"Nous vous informons que l’appel à candidatures pour la bourse co-financée\n" +
        "                (« Henri Poincaré ») par la République du Tatarstan et l’Ambassade de France à Moscou a été prolongé jusqu’au 14 février 2022.",
    blogtext1:"Nous vous invitons à lire quels sont les avantages de cette bourse et quelles sont les modalités de dépôt de votre dossier ci-dessous.",
    heading:"Domaines de formation :",
    text:"Études dans les Grandes Écoles françaises (école de commerce, d’ingénieur, etc...) ;",
    text1:"Études en Masters I ou II dans toutes les disciplines enseignées en France.",
    text2:"",
    text3:"La liste des programmes d’études en langue anglaise dans les universités françaises se trouveci-dessous :",
    text3a:"http://www.campusfrance.org/en/page/programs-taught-english :",
    heading1:"Conditions d’éligibilité :",
    text4:"Être de nationalité russe et être domicilié au Tatarstan ;",
    text5:"Être   diplômé   de   l’enseignement   supérieur   russe   au   plus   tard   à   la   fin   de   l’année universitaire du dépôt de la candidature ;",
    text6:"Être âgé de moins de 26 ans au moment du départ en France ;",
    text7:"Avoir de bonnes connaissances en langue française ou anglaise (test obligatoire Pour les étudiants non diplômés en langues) ;",
    text8:"Avoir   déposé   sa   candidature   dans   une   ou   plusieurs   établissements   d'enseignement supérieur français.",
    heading2:"Critères de sélection :",
    text9:"Qualité du parcours académique au Tatarstan.",
    text10:"Pertinence du projet académique en France.",
    text11:"Excellence de l'établissement d'accueil en France.",
    text12:"Intérêt pour la coopération franco-tatare.",
    text13:"Compétences linguistiques.",
    text14:"Activités extra-académiques du candidat.",
    heading3:"Avantage de la bourse :",
    text15:"Allocation de vie pendant la durée de la formation (délivré par le ministère de l’Éducationet de la Science du Tatarstan dans le cadre du programme «Algarych») :",
    text16:"Statut de boursier du gouvernement français, incluant :",
    text17:"La gratuité des frais de visa ;",
    text18:"La gratuité de la procédure Campus France ;",
    text19:"L’exemption des frais de scolarité ;",
    text20:"La prise en charge de la couverture sociale pendant la durée des études ;",
    text21:"L’accès prioritaire en logement universitaire ;",
    text22:"L’accompagnement de l’Ambassade de France.",
    heading4:"Documents exigés pour le dépôt des candidatures : ",
    text23:"Lettre d’inscription ou de préinscription de l’université et/ou\n" +
        "                    liste Des établissements choisis dans le dossier personnel Campus France (informations sur La procédure Campus France :",
    text23a:"http://www.russie.campusfrance.org/ :",
    text24:"Lettre de recommandation  (de l’université  d’origine, possibilité  de fournir une lettre de recommandation de l’employeur si l’étudiant travaille) ;",
    text25:"Curriculum vitae ;",
    text26:"Lettre de motivation ;",
    text27:"Copie et traduction certifiées par un notaire ou l’université du diplôme russe  ... le plus élevé et ... du dernier relevé de notes ;",
    text28:"Copie de la carte étudiant ou du certificat de travail si vous n’êtes pas étudiant ;",
    text29:"est   de   connaissance   du   français   (DALF/DELF,   TCF)   ou   de   connaissance   de   langueanglaise si le programme se déroule en anglais.",
    text30:"Pour déposer votre dossier, merci de vous rendre sur la page «Bourse d’études et de couverture\n" +
        "sociale «Master» » et cliquer sur «Déposer ma candidature». Une fois que vous aurez\n" +
        "déposé votre candidature, vous devrez obligatoirement envoyer un email à MadameGALLANT Anouchka afin de la prévenir :",
    text30a:"anouchka.gallant@diplomatie.gouv.fr :",
    heading5:"",

}