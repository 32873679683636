import axios from "axios";
import router from "../router";
import store from '../store/store'

export const interceptorsUtil = function () {
    axios.interceptors.response.use(
        function (response) {
            // Call was successful, don't do anything special.
            return response;
        },
        async function (error) {
            switch (error.response.status) {
                case 401: // Not logged in
                    await store.dispatch('auth/logout')
                    router.push('/')
                    break;
                case 419: // Session expired
                case 503: // Down for maintenance
                    // Bounce the user to the login screen with a redirect back
                    // router.push({path: '/login'});
                    break;
                case 500:
                    console.log('Oops, something went wrong!');
                    break;
                default:
                    // Allow individual requests to handle other errors
                    return Promise.reject(error);
            }
        }
    );
};

export const preventAuthUser = function (to, info, next) {

    //to.name.includes('student') - for create application (new forms)

    if(info.user &&
        !info.hasApplication &&
        info.from.path === '/not-application'
    ) {
        next('/not-application')
    }
    //
    // if(info.user &&
    //     !info.hasApplication &&
    //     to.path !== '/profile' &&
    //     !to.name.includes('student') &&
    //     !to.name.includes('registration') &&
    //     to.path !== '/not-application'
    // ) {
    //     console.log("profile")
    //     // next('/profile');
    // }
    else if (info.user && info.user.token && (to.path === '/login' || to.path === '/')) {
        next('/application');
    }
    if(!info.user && to.meta.layout === "main") {
        next('/');
    }
    // if(info.user && to.meta.layout === "notAuth") {
    //     next('/identity');
    // }

    next()

    // if (user) {
    //     next()
    // } else {
    //     if (
    //         to !== '/login'
    //         && to !== '/forgot-password'
    //         && to.includes('/registration') === false) {
    //         next('/login');
    //     } else {
    //         next();
    //     }
    // }
};

