import home from "./fr/home";
import header from "./fr/header";
import scholarships from "./fr/scholarships";
import faq from "./fr/faq";
import contacts from "./fr/contacts";
import breadcrumbs from "./fr/breadcrumbs";
import identity from "./fr/identity";
import hasAccountModal from "./fr/hasAccountModal";
import profile from "./fr/profile";
import project from "./fr/project";
import application from "./fr/application";
import blog from "./fr/blog";
import master from "./fr/master";
import edhec from "./fr/edhec";
import audencia from "./fr/audencia";
import cuf from "./fr/cuf";
import footer from "./fr/footer";
import resetPassword from "./fr/resetPassword";
import newMessage from "./fr/newMessage";

export default {
    ...header,
    ...home,
    ...scholarships,
    ...faq,
    ...contacts,
    ...breadcrumbs,
    ...identity,
    ...hasAccountModal,
    ...profile,
    ...project,
    ...application,
    ...blog,
    ...master,
    ...edhec,
    ...cuf,
    ...footer,
    ...resetPassword,
    ...newMessage,
    ...audencia
}