export const printArray = {
    methods: {
        printArray(errors) {
            if (typeof errors != "undefined"
                && errors != null
                && errors.length > 0
                && typeof errors == 'object') {
                return errors.join(', ')
            }

            return errors
        },
    }
}