<template>
  <div class="error-wrap" v-if="errors">
    <p v-for="(error, i) in errors" :key="i">{{ printArray(error) }}</p>
  </div>
</template>

<script>
import {printArray} from "@/mixins/printArray"

export default {
  name: 'Errors',
  props: ['errors'],
  mixins: [
    printArray
  ]
}
</script>

<style scoped lang="scss">
  .error-wrap {
    margin-bottom: 20px;

    p {
      color: red;
    }
  }
</style>
