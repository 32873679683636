export default {
    scholarshipEdhec: 'Совместная стипендия с EDHEC business school',
    edhecTitle: 'Бизнес школа EDHEC и Посольство Франции в России запускают' +
        ' совместную стипендиальную программу на 2023-2024 учебный год',
    edhecText1: 'Условия участия:',
    edhecText11: 'Являться гражданином Российской Федерации и обучаться в одном из вузов России;',
    edhecText12: 'Подать заявку на 1-годичную магистерскую программу ' +
        'по бизнес-менеджменту либо финансовой экономике в бизнес школе EDHEC;',
    edhecText13: 'Иметь отличную успеваемость в университете, а такжеиметь внятные' +
        ' академические стремления и четкие профессиональные цели.',
    edhecText2: 'Преимущества стипендии:',
    edhecText21: 'Оплата стоимости обучения за программу (до 27 000 евро) школой EDHEC;',
    edhecText22: 'Стипендия на социальное покрытие, предоставляемая на 10 месяцев Посольством' +
        ' Франции в России. Студент получает статус стипендиата французского правительства (бесплатная виза,' +
        ' приоритет при получении комнаты встуденческом общежитии, студенческая социальная страховка).',
    edhecText3: 'Крайний срок рассмотрения кандидатов на стипендию: 31/05/2023',
    edhecText31: 'Студенты должны подать заявку на 1-годичную программу (MSc).',
    edhecText32: 'В случае принятия в школу оплатить 1-й взнос.',
    edhecText33: 'В случае получения стипендии 1-й взнос возвращается студенту',
    edecInfo1: 'Вопросы направлять по адресу:',
    edecInfo2: 'Дополнительная информация на сайте школы:',
    edecInfo3: 'Представитель школы EDHEC в России, Юлия Дубина:',
}