var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isFaqPage && !_vm.isContactsPage && !_vm.isBlogPage)?_c('div',{staticClass:"left-menu_wrap"},[_c('div',{staticClass:"left-menu"},[(!_vm.registrationForms)?_c('div',[_c('p',{staticClass:"menu-title"},[_vm._v(_vm._s(_vm.$t('scholarshipsTitle')))]),_vm._l((_vm.menu),function(item,i){return _c('div',{key:i,staticClass:"menu-item",class:{'menu-item_active': item.isActive, 'disabledClass':item.isDisabled},on:{"click":function($event){return _vm.selectMenuItem(item)}}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")])])}),_c('p',{staticClass:"menu-title"},[_vm._v(_vm._s(_vm.$t('partnersScholarshipsTitle')))]),_vm._l((_vm.menuPartners),function(item,i){return _c('div',{key:i + 10,staticClass:"menu-item",class:{'menu-item_active': item.isActive,'disabledClass':item.isDisabled},on:{"click":function($event){return _vm.selectMenuItem(item)}}},[_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")])})],2):_c('div',{staticClass:"registration-forms"},[_c('router-link',{staticClass:"form-item",class:{'form-item_active': _vm.isIdentityForm},attrs:{"to":{
                name: 'registration',
                params: {
                    id: _vm.$route.params.id,
                    studentId: _vm.$route.params.studentId,
                    profileId: _vm.$route.params.profileId,
                }
            }}},[_c('div',{staticClass:"number"},[_vm._v("1")]),_vm._v(" "+_vm._s(_vm.$t('identity'))+" ")]),_c('router-link',{staticClass:"form-item",class:{'form-item_active': _vm.isProfileForm},attrs:{"to":{
                name: 'student-profile',
                params: {
                    id: _vm.$route.params.id,
                    studentId: _vm.$route.params.studentId,
                    profileId: _vm.$route.params.profileId,
                }
            }}},[_c('div',{staticClass:"number"},[_vm._v("2")]),_vm._v(" "+_vm._s(_vm.$t('profile'))+" ")]),_c('router-link',{staticClass:"form-item",class:{'form-item_active': _vm.isProjectForm},attrs:{"to":{
                name: 'student-project',
                params: {
                    id: _vm.$route.params.id,
                    studentId: _vm.$route.params.studentId,
                    profileId: _vm.$route.params.profileId,
                }
            }}},[_c('div',{staticClass:"number"},[_vm._v("3")]),_vm._v(" "+_vm._s(_vm.$t('project'))+" ")])],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }