export default {
    faq_title: 'Часто задаваемые вопросы по заявкам BGF',
    body: 'Приведенную ниже информацию можно скачать в формате Word по этой ссылке : <a class="body-link" href="https://ru.ambafrance.org/Podacha-zayavki-na-stipendiyu-BGF-chasto-zadavaemye-voprosy">https://ru.ambafrance.org/Podacha-zayavki-na-stipendiyu-BGF-chasto-zadavaemye-voprosy</a> ',
    acceptability: '1. Приемлемость',
    acceptability_text:'Текущая международная ситуация приводит к приостановке всех новых двусторонних отношений между Францией и Россией. Поэтому теоретически невозможно претендовать на эту стипендию в рамках новой двойной степени, за исключением случаев, когда принимающий институт подтверждает обратное.',
    acceptability_text1:'Студенты, обучающиеся в магистратуре 1 во Франции, могут претендовать на стипендии и социальное обеспечение при условии, что у них есть российский диплом высшего образования. В этом случае необходимо предоставить письмо о предварительном зачислении в М2 (университет может указать, например, «зачислен в М2 при условии успешной сдачи экзаменов М1»). Если студент меняет университет между М1 и М2, необходимо предоставить письмо, подтверждающее, что он/она подал/а заявление на М2. Необходимо приложить результаты транскрипции российского диплома, а также первого семестра М1, проведенного во Франции. Мы советуем вам предоставить два рекомендательных письма: одно из французского университета и одно из российского. Как и другие кандидаты, студенты должны предоставить и другие запрашиваемые документы (сопроводительное письмо, резюме и т.д.).',
    general:'2. Общий',
    general_text:' Все документы должны быть загружены в формате pdf и не должны превышать по размеру 2 МБ.',
    general_text1:'Документы могут быть переведены и заверены на английском или французском языке.',
    languages:'3. Языки',
    languages_text: 'Важно подтвердить свой уровень владения французским языком, если вы собираетесь учиться на французском языке. Поэтому требуется языковой сертификат. Если вы учитесь на английском языке, загрузите сертификат о знании английского языка вместо запрашиваемого сертификата о знании французского языка.',
    languages_text1:'Если у вас нет сертификата (ожидание языкового экзамена, обладатель эквивалента…), вы должны разместить документ (в формате pdf), который заменит этот сертификат, и добавить комментарий в «общих комментариях», чтобы объяснить, чем этот документ похож.',
    languages_text2: 'Затем запрашивается сертификат о знании второго языка. Если вы не знаете других языков, добавьте тот документ, который вы уже загрузили в разделе «первый язык».',
    resume_faq:'4. Резюме, сопроводительное письмо, рекомендательные письма, справка с работы',
    resume_text:'Если вы работаете, справка с работы должна быть добавлена после резюме в том же pdf. Если Вы безработный, необходимо приложить копию трудовой книжки. Её не нужно переводить.',
    resume_text1:'Для подачи заявки на получение стипендии BGF требуется 1 или 2 рекомендательных письма. При подготовке заявления вам нужно будет собрать их в едином pdf-файле и поместить в раздел «Рекомендации». Официального формата рекомендательного письма не существует. Если возможно, письмо может быть заверено печатью.',
    resume_text2: 'Сопроводительное письмо не может быть написано вручную. В целях обеспечения удобочитаемости мы просим вас никогда не прилагать к заявлению документы, написанные от руки. Желательно присылать его нам в машинописном виде, в формате pdf.',
	resume_text3: 'Эксель-форма может быть заполнена на английском языке. Вы можете указать только одну область обучения.',
    certificate_of_registration:'5. Свидетельство о регистрации или предварительной регистрации',
    certificate_of_registration_body:'Вы подали заявки в несколько университетов через сайт Campus France, создав единую учетную запись.',
    certificate_of_registration_body1: 'Дата окончания приема заявок на получение стипендии BGF часто наступает раньше, чем время начала приема заявок университетами. В этом случае вы не сможете вовремя получить свидетельство о регистрации или предварительной регистрации.',
    certificate_of_registration_text:'Просто пришлите нам скриншот (в формате pdf) выборов, которые вы сделали на платформе «Etudes en France».',
    certificate_of_registration_text1:'Если учебное заведение, в которое вы подали заявление, не является частью процедуры Campus France, достаточно приложить скриншот (в формате pdf) вашей электронной переписки с этим учебным заведением, подтверждающий ваше намерение зарегистрироваться.',
    certificate_of_registration_text2: 'Если вы были зачислены в один/несколько университетов до объявления результатов, нет необходимости отправлять нам эти подтверждения. Они будут запрошены после того, как вы получите стипендию.',
	faq_project:'6. Выбор учебных программ',
    faq_project_body:'Вы можете планировать до 7 проектов. При заполнении формы в формате Excel внесите информацию по трем приоритетным для Вас программы.',
    send_application: '7. Отправка вашего файла',
	send_application_text: 'Кандидаты должны отправить заявку candidatures.bourses@bgfrussie.ru согласно установленному календарю, каждый документ в формате .pdf (за исключением заполненной формы заявления, которая должна быть отправлена в формате Excel) и размером не более 2 МБ каждый.',
	results: '8. Результаты',
    results_text : 'Вы будете уведомлены по электронной почте о результатах нашего отбора, независимо от того, успешно вы прошли отбор или нет.',
    results_text1: 'Если вы были зачислены в один/несколько университетов до объявления результатов, нет необходимости отправлять нам эти подтверждения. Они будут запрошены после того, как вы получите стипендию.',
    faq_help : '9. Помощь',
    faq_help_text:'Выбор курса обучения во Франции: <a class="body-link" href="https://www.campusfrance.org/fr/node/1309">www.campusfrance.org/fr/node/1309</a>',
    faq_help_text1:'Программы обучения на английском языке: <a class="body-link" href="https://www.campusfrance.org/fria/taughtie/index.html">www.campusfrance.org/fria/taughtie/index.html</a> ',
    faq_help_text2:'Процедура Кампус Франс для студентов с стипендиями: <a class="body-link" href="https://ru.ambafrance.org/Demander-un-visa-pour-la-France-en-Russie">https://ru.ambafrance.org/Demander-un-visa-pour-la-France-en-Russie</a>  / <a class="body-link" href="https://www.campusfrance.org/fr/visa-long-sejour-etudiant">https://www.campusfrance.org/fr/visa-long-sejour-etudiant</a>',
    faq_help_text3:'Процедура подачи заявления на долгосрочную студенческую визу: <a class="body-link" href="https://www.campusfrance.org/fr/visa-long-sejour-etudiant">https://www.campusfrance.org/fr/visa-long-sejour-etudiant</a>',
    faq_help_text4:'Список центров тестирования французского языка: <a href="https://www.france-education-international.fr/centres-d-examen/carte">https://www.france-education-international.fr/centres-d-examen/carte</a> ',
}