export default {
    blogQuestionsTitle: 'Недавние статьи',
    blogTitle1: 'Что такое мотивационное письмо? Что мне надо написать?',
    blogTitle2: 'Что такое «рекомендательное письмо»? Кто должен его написать?',
    // blogTitle3: 'Стипендиальная программа «Анри Пуанкаре» между Министерством образования и науки Республики Татарстан и Посольством Франции в России',
    blogTitle3: 'Стипендиальная программа «Анри Пуанкаре» между Министерством образования и науки Республики Татарстан и Посольством Франции в России',
    blogAnswer1: 'Мотивационное письмо – это письмо, в котором вы излагаете,' +
        ' по какой причине вы подали вашу заявку и почему вы считаете, что являетесь ' +
        'подходящим кандидатом. Прочитав письмо, оценщики должны представить себе ваш характер' +
        ' и ваши способности. Поэтому внимательно следите за правописанием и аргументацией! Это лучше писать мало, но аккуратно.',
    blogAnswer11: 'Мотивационное письмо должно быть написано с соблюдением этикета деловой переписки.' +
        'Не стеняйтесь спрашивать и просить помощи у знающих французский язык друзей (несколько <a>советов</a> для наглядности)',
    blogAnswer2: 'Рекомендательное письмо – это письмо, в котором преподаватель, который уже работал с Вами, излагает причины, ' +
        'убедившие его в том, что вы являетесь достаточно компетентным лицом, чтобы получить от нас финансовую поддержку. ',
    blogAnswer22: 'В частности, в письме должны быть: число, когда письмо было написанным, фамилия вашего референта, его должность,' +
        ' информация о том, как вы с ним связались, перечень совместно проведенных проектов, соображения о том,' +
        ' почему вы были бы подходящим кандидатом, подпись референта, печать университета или работодателя.',
    blogAnswer3: '© Laboratoire d Histoire des sciences et de philosophie - Archives Henri Poincaré (CNRS, université de Lorraine)',
    blogAnswer33: '',
    source: 'источник',
    shortBlogTitle1: 'Что такое «мотивационное письмо»?',
    shortBlogTitle2: 'Что такое «рекомендательное письмо»?',
    shortBlogTitle3: 'Университеты с лучшим показателем успешности получения степени',
    shortBlogDescription1: 'Мотивационное письмо – это письмо, в котором вы излагаете, по какой причине вы...',
    shortBlogDescription2: 'Рекомендательное письмо – это письмо, в котором преподаватель, который уже работал...',
    shortBlogDescription3: '71 французский университет был проранжирован по уровню успешности студентов...',
    blogDate: '12 июня 2021',
    blogtext:"Информируем Вас, что прием заявок на участие в стипендиальной программе, софинансируемой Республикой Татарстан и Посольством " +
        "Франции в Москве (программа « Анри Пуанкаре ») продлен до 14 февраля 2022 года .",
    blogtext1:"Ниже Вы можете ознакомиться с преимуществами стипендиальной программы,  а также с условиями и правилами подачи заявки .",
    heading:"Возможные программы обучения :",
    text:"Обучение и получение диплома в высшей школе (бизнес-школы, инженерные школы и пр.) .",
    text1:"Обучение и получение диплома по программам магистратуры 1-ого или 2-ого года .",
    text2:"Другие программы по различным дисциплинам, преподаваемым во Франции .",
    text3:"Cписок программ обучения во французских вузах на английском языке",
    text3a:"http://www.campusfrance.org/en/page/programs-taught-english",
    heading1:"Требования к кандидатам :",
    text4:"Наличие российского диплома о высшем образовании (диплом бакалавра, специалиста или магистра), выданного не позднее конца учебного года подачи заявки на стипендию . ;",
    text5:"Гражданство Российской Федерации и проживание на территории Республики Татарстан до момента получения гранта,",
    text6:"Возраст, не превышающий 26 лет на дату предполагаемого отъезда во Францию,",
    text7:"Кандидаты должны подать заявку на поступление в один из французских вузов на программы магистратуры 1 или 2 .",
    text8:"",
    heading2:"Критерии отбора :",
    text9:"Качество представленного плана обучения во Франции, качество принимающей программы обучения во Франции,",
    text10:"Академическая успеваемость кандидата в Республике Татарстан,",
    text11:"Актуальность мобильности для сотрудничества между Францией и Татарстаном,",
    text12:"Хорошее знание французского языка (или английского языка, если программ на английском)",
    text13:"Вне учебной деятельности кандидата .",
    text14:"",
    heading3:"Финансовые условия :",
    text15:"Министерство образования и науки Республики Татарстан обеспечивает в рамках программы «Алгарыш» :",
    text16:"Стипендию в размере 800€ в месяц в течение всего периода обучения",
    text17:"Авиаперелет туда и обратно",
    heading4:"Французское правительство предоставляет :",
    text18:"Статус стипендиата французского правительства,",
    text19:"Бесплатную визу и процедуру Кампюс Франс,",
    text20:"Бесплатное обучение,",
    text21:"Медицинскую страховку,",
    text22:"Приоритет при получении комнат в студенческих общежитиях,",
    text222:"Консультационную поддержку Посольства Франции .",
    heading5:"Список требуемых документов:",
    text23:"Подтверждение или предварительное подтверждение о зачислении во французский вуз И/ИЛИ список выбранных вузов из личного досье Кампюс Франс (информация о процедуре Кампюс Франс на сайте",
    text23a:"www.russie.campusfrance.org)",
    text24:"Рекомендательное письмо (от российского вуза, в случае, если студент работает -от компании)",
    text25:"Резюме",
    text26:"Мотивационное письмо",
    text27:"Заверенная нотариально или российским вузом копия российского диплома о высшем образовании и его заверенный нотариально или российским вузом перевод",
    text28:"Копия студенческого билета",
    text29:"Сертификат, подтверждающий уровень владения французским языком (DELF/DALF, TCF) или английским языком (если программа обучения на английском) .",
    text30:"Для подачи заявки просьба перейти на страницу « Стипендальная программа правительства Франции ‘Магистр’ » и нажать на « Подать заявку ». Затем, необходимо отправить электронное письмо Анушке ГАЛЛАН, чтобы она смогла проверить Вашу заявку :",
    text30a:"anouchka.gallant@diplomatie.gouv.fr",
}