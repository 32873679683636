export default {
    applicationListLastName: 'Nom',
    applicationListFirstName: 'Prénom',
    applicationListCreated: 'Créé',
    applicationListInstitution: 'Etablissement',
    applicationListStatus: 'Statut',
    applicationAcceptDeclineMessage: 'Attention! Cette action est définitive. Vous ne pourrez pas l’annuler',
    forgotPassword: 'Mot de passe oublié',
    password: 'Mot de passe',
    signin: 'Se connecter',
    login: 'Connexion',
    edit: 'Editer'
}