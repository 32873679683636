<template>
    <div class="footer_wrapper">
        <div class="footer">
            <router-link to="/" class="footer_logo">
                <img src="@/assets/images/logo.svg" alt="logo">
            </router-link>
            <div class="footer_content">
                <div class="footer-text">
                    <p class="footer-title">{{ $t('embassy') }}</p>
                    <p>{{ $t('address') }}</p>
                </div>
                <div class="footer-text">
                    <p class="footer-title">{{ $t('contacts') }}</p>
                    <p>{{ $t('department') }} <br />
                        bgf.moscou-amba@diplomatie.gouv.fr <br />
                        +7 (495) 937 15 14 <br>
                        +7 (495) 937 15 09 <br />
                        +7 (495) 937 24 97
                    </p>
                </div>
            </div>

        </div>
        <div class="footer contacts">
            <div class="address">© 2021. {{ $t('supported') }}</div>
            <div class="social">
                <a href="https://twitter.com/franceenrussie"><img src="@/assets/images/twiter.svg" /></a>
                <a href="https://www.facebook.com/sste.moscou.amba/"><img src="@/assets/images/facebook.svg" /></a>
                <a href="https://ru.ambafrance.org/-Enseignement-Superieur-Recherche-et-Innovation-"><img src="@/assets/images/gmail.svg" /></a>
                <a href="https://www.instagram.com/France_en_Russie/"><img src="@/assets/images/insta.svg" /></a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'footer-section',
}
</script>

<style lang="scss" scoped>
.footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    max-width: 1440px;
    @media(max-width: 1440px) {
        width: 90%;
    }
    @media(max-width: 1024px) {
        flex-direction: column;
    }

    &_wrapper {
        margin-top: 60px;
        //margin-top: 100px;
        background: #F5F8FD;
        height: 370px;
        padding: 10px 0;
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media(max-width: 1024px) {
            height: 545px;
        }

        @media(max-width: 660px) {
            padding: 30px 0;
            height: 590px;
        }

        @media(max-width: 520px) {
            height: 640px;
        }

        @media(max-width: 370px) {
            height: 670px;
        }

        .contacts {
            margin-top: 80px;

            .social {
                display: flex;
                margin-right: 14%;

                img {
                    margin-left: 20px;

                    &:first-child {
                        @media (max-width: 1024px) {
                            margin-left: 0;
                        }
                    }
                }

                @media(max-width: 1440px) {
                    margin-right: 17%;
                }

                @media (max-width: 1024px) {
                    margin-bottom: 30px;
                }
            }

            @media(max-width: 1024px) {
                flex-direction: column-reverse;
                margin-top: 50px;
            }
        }
    }

    &_logo {
        width: 130px;
        cursor: pointer;
        @media(max-width: 1024px) {
            margin-bottom: 30px;
        }
    }

    &_content {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        max-width: 690px;

        @media(max-width: 660px) {
            flex-direction: column;
        }
    }

    &-text {

        &:last-child {
            width: 65%;
            margin-left: 100px;

            @media (max-width: 1024px) {
                width: 72%;
                margin-left: 80px;
            }

            @media (max-width: 660px) {
                margin-left: 0;
                margin-top: 20px;
            }
        }

        p {
            font-size: 18px;
            line-height: 32px;
        }

        .footer-title {
            font-weight: bold;
            margin-bottom: 20px;

            @media(max-width: 660px) {
                margin-bottom: 10px;
            }
        }
    }
}

</style>
