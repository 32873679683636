<template>
    <div class="chat-left-menu">
        <router-link to="/" class="logo">
            <img src="@/assets/images/logo.svg" alt="logo">
        </router-link>

        <router-link to="/messages" class="back-btn">
            <img src="@/assets/images/arrow_back.svg" alt="back"/>
            Retour
        </router-link>

        <div class="text">
            Message
        </div>

    </div>
</template>

<script>
export default {
    name: 'chat-left-menu'
}
</script>

<style lang="scss" scoped>
a {
    color: black;
    text-decoration: none;
}
.md-theme-default a:not(.md-button):hover {
    color: black;
}
.chat-left-menu {
    width: 300px;
    background: #F5F8FD;
    text-align: left;
    padding-top: 20px;
    height: 100vh;

    .logo {
        margin: 0 0 0 30px;
        img {
            width: 130px;
        }
    }

    .back-btn {
        display: flex;
        align-items: center;
        margin: 25px 0 10px 30px;
        text-decoration: none;
        color: #000000;

        img {
            margin-right: 10px;
        }
    }

    .text {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        background: #FFFFFF;
        padding: 10px 0 10px 30px;
    }
}
</style>