export default {
    // identity: 'Identity',
    // name: 'Фамилия',
    // middleName: 'Отчество',
    // firstName: 'Имя',
    // gender: 'Пол',
    // birthDate: 'Дата рождения',
    // birthplace: 'Место рождения',
    // nationality: 'Национальность',
    // address1: 'Адрес 1',
    // address2: 'Дополнительный адрес',
    // zipCode: 'Почтовый индекс',
    // city: 'Город',
    // country: 'Страна',
    // telephone: 'Телефон',
    // password: 'Пароль',
    // confirmPassword: 'Подтверждение пароля',
    // savedMessage: 'Изменение сохранено',
    identity: 'Identité',
    name: 'Nom',
    middleName: 'Patronyme',
    firstName: 'Prénom',
    gender: 'Sexe',
    birthDate: 'Date de naissance',
    birthplace: 'Lieu de naissance',
    nationality: 'Nationalité',
    address1: 'Adresse 1',
    address2: 'Complément d’adresse',
    zipCode: 'Code postal',
    city: 'Ville',
    country: 'Pays',
    telephone: 'Téléphone',
    password: 'Mot de passe',
    confirmPassword: 'Confirmation mot de passe',
    savedMessage: 'Modification enregistrée',
}