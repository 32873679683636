export default {
    sliderTitle: 'Bourse du gouvernement français (Master)- L\'appel aux candidatures débutera fin novembre',
    more: 'En savoir plus',
    homeTitle: 'Bienvenue sur le site des bourses du gouvernement français',
    scholarshipMetchnikov: 'Bourse Metchnikov',
    scholarshipOstrogradski: 'Bourse doctorale Ostrogradski',
    scholarshipVernadski: 'Bourse Vernadski',
    scholarshipMaster: 'Bourse d’études et de couverture sociale “Master”',
    partnerScholarshipsTitle: 'Programmes en partenariat avec l’Ambassade de France en Russie',
    scholarshipHenri: 'Bourse d’études Henri Poincaré',
    submit: 'Déposer ma candidature',
    questions: 'Questions fréquentes',
    logout: 'Se déconnecter'
}