export default {
    // applicationListLastName: 'Last name',
    // applicationListFirstName: 'First name',
    // applicationListCreated: 'Created',
    // applicationListInstitution: 'Institution',
    // applicationListStatus: 'Status',
    // applicationAcceptDeclineMessage: 'Предупреждение! Это действие окончательное. Вы не сможете его отменить',
    // forgotPassword: 'Forgot Password',
    // password: 'Password',
    // signin: 'Sign in',
    // login: 'Login',
    // edit: 'Edit',

    applicationListLastName: 'Nom',
    applicationListFirstName: 'Prénom',
    applicationListCreated: 'Créé',
    applicationListInstitution: 'Etablissement',
    applicationListStatus: 'Statut',
    applicationAcceptDeclineMessage: 'Attention! Cette action est définitive. Vous ne pourrez pas l’annuler',
    forgotPassword: 'Mot de passe oublié',
    password: 'Mot de passe',
    signin: 'Se connecter',
    login: 'Вход',
    edit: 'Editer'
}