import Vue from 'vue'
import App from './App.vue'
import axios from "axios";
import store from "./store/store"
import router from "./router"
import VueMaterial from 'vue-material';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';
import {interceptorsUtil, preventAuthUser} from './utils';
import {i18n} from './plugins/i18n'
import Notifications from 'vue-notification'
Vue.use(VueMaterial)
Vue.use(Notifications)

Vue.use(require('vue-moment'));
Vue.prototype.$axios = axios;

Vue.config.productionTip = false

Vue.material.locale.dateFormat = 'dd-MM-yyyy'
Vue.material.locale.days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
Vue.material.locale.shortDays = ['Dim', 'Lun', 'Mar', 'Mec', 'Jeu', 'Ven', 'Sam']
Vue.material.locale.shorterDays = ['D', 'L', 'M', 'M', 'J', 'V', 'S']
Vue.material.locale.months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
Vue.material.locale.shortMonths = ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc']
Vue.material.locale.cancel = 'Annuler'

Vue.material = {
    ...Vue.material,
}

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

Vue.component('v-select', vSelect)

Vue.use({store});

interceptorsUtil();

router.beforeEach((to, from, next) => {
  const userInfo = JSON.parse(localStorage.getItem('bgfCandidate'));
  const hasApplication = +localStorage.getItem('currentScholarshipTypeId');

  const info = {
    user: userInfo,
    hasApplication,
    from
  }

  preventAuthUser(to, info, next)

  window.scroll(0,  0)
});

new Vue({
  i18n,
  store,
  router,
  render: h => h(App),
    created() {
        const userInfo = localStorage.getItem('bgfCandidate');

        if (userInfo) {
            const userData = JSON.parse(userInfo);
            this.$store.commit('auth/setUserData', userData)
        }

        axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response.status === 401) {
                    this.$store.dispatch('auth/logout')
                }
                return Promise.reject(error)
            }
        )
    }
}).$mount('#app')
