export default {
    scholarshipAudencia: 'Bourse cofinancée avec l’AUDENCIA Business School',
    audenciaTitle: 'Audencia Business School et l’Ambassade de France en Russie mettent en place une bourse cofinancée pour l’année 2023-2025',
    audenciaText1: 'Conditions pour participer :',
    audenciaText11: 'Être de nationalité russe et être étudiant en Russie ;',
    audenciaText12: 'Postuler au programme Master in Management Grande Ecole à Audencia Business School (programme de 2 ans) ;',
    audenciaText13: 'Avoir un excellent niveau universitaire ainsi qu’un projet professionnel construit et pertinent.',
    audenciaText2: 'Avantages de la bourse :',
    audenciaText21: 'Une  réduction  de  50%  des  frais  de  scolarité  du  programme  Master in Management Grande Ecole;',
    audenciaText22: 'Bourse de couverture sociale pour une durée de 10 mois de la part de l’Ambassade de  France  en  Russie.  Cette  bourse  offre  le  statut  de  boursier  du  gouvernement français  (gratuité  des  frais  de  visa,  des  frais  d’inscription,  priorité  pour  l’obtention d’une chambre en cité universitaire, sécurité sociale étudiante).',
    audenciaText3: 'Date limite de candidature :',
    audenciaText31: 'Les  candidats  ont  jusqu’au  01/02/2023  pour  déposer  leur  candidature  au  programme  de Master en Management (2 ans).',
    audenciaText32: 'Les étudiants doivent rédiger une lettre de motivation sur les raisons pour lesquelles ils veulent étudier le management en France.',
    audenciaInfo1: 'Pour postuler :',
    audenciaInfo3: 'Merci de prévenir Madame BURAKOVA Natalia dès que vous aurez postulé afin que votre candidature soit prise en compte .',
    audenciaQuestions: 'Plus d’informations',
    audenciaQuestion1: 'Site internet :<a href="https://master.audencia.com/en/programmes-english/master-management-grande-ecole/"> Audencia Business School:</a>',
    audenciaQuestion2: 'Personne à contacter : Natalia BURAKOVA, Représentante d’Audencia Business School en Russie <a href="russia@audencia.com">russia@audencia.com</a>'
}