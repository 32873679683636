export default {
    scholarshipCUF: 'Bourses des Collèges Universitaires Français',
    cufItalicText: 'Les Collèges Universitaires Français de Moscou et de Saint-Pétersbourg sont des établissements publics soutenus parle ' +
        'Ministère des Affaires Etrangères, le Ministère de l\'Education Nationale, le Ministère de l\'Enseignement Supérieur et de' +
        ' la Recherche et les universités partenaires* françaises. Ils délivrent des formations universitaires, d\'une durée de deux ans, ' +
        'en section francophone ou russophone.',
    cufTextForMaster: 'Les domaines d\'études : droit, histoire, sociologie, littérature, philosophie.' +
        '<p class="cuf-text_sub">Le diplôme délivré à la fin de la deuxième année francophone est reconnu par 10 universités ' +
        'partenaires* comme un équivalent au diplôme de Master 1. Les étudiants titulaires de ce diplôme ' +
        'sont donc autorisés à s\'inscrire dans une université française pour une année universitaire, ' +
        'en vue de l\'obtention d’un diplôme de Master 2.</p>' +
        '<p class="cuf-text_sub">Afin de soutenir financièrement les meilleurs étudiants de chaque discipline, une quinzaine ' +
        'de bourses sont attribuées chaque année aux étudiants francophones de deuxième année, toutes disciplines et tous Collèges confondus.</p>',
    cufText1: 'L\'obtention de la bourse est conditionnée :',
    cufText11: 'A l\'inscription préalable au Collège Universitaire Français (Moscou ou Saint-Pétersbourg)',
    cufText12: 'Au dépôt d\'un dossier de candidature auprés du CUF, avant la date limite du 31/03/2022.',
    cufText13: 'A l\'avis favorable du jury chargé d\'examiner les demandes (oraux prévus au mois de juin).',
    cufText2: '<p class="cuf-text_sub">Les étudiants sélectionnés reçoivent une bourse d\'une durée de neuf mois (une année universitaire) destinée à couvrir une partie des frais de la vie quotidienne en France.</p>' +
        '<p class="cuf-text_sub">Ces bourses ne sont pas renouvelables, et ne peuvent pas être cumulées avec une autre bourse ou prolongées par une bourse d\'études du gouvernement français.</p>',
    cufText3: 'Étudiants intéressés par des cours au Collège universitaire:',
    cufText31: 'Site du Collège Universitaire Français de Moscou: <a href="http://www.moscuf.org/fr" target="_blank">http://www.moscuf.org/fr</a>',
    cufText32: 'Site Collège Universitaire de Saint-Pétersbourg: <a href="http://cuf.spbu.ru/" target="_blank">www.cuf.spbu.ru</a>',
    cufPartners: '* universités partenaires: <a href="#">Paris I – Panthéon Sorbonne, Paris II – Panthéon Assas, Paris IV – Paris Sorbonne, Paris V –René Descartes, Paris VIII – Vincennes Saint-Denis,' +
        ' Université d\'Aix-Marseille, EHESS (Ecole des Hautes Etudes en Sciences Sociales), ENS Cachan (Ecole Normale Supérieure de Cachan), ENS de Paris (Ecole Normale Supérieure dela rue d\'Ulm)</a>',
    cufFinished: 'Les étudiants' +
        ' n’ont pas besoin de fournir de lettre de recommandation ni de certificat de langue. Pour des raisons techniques, merci ' +
        'de joindre un fichier "Word" vierge à la place pour pouvoir valider votre candidature. Les étudiants doivent fournir un CV,' +
        ' une lettre de préadmission de l’établissement partenaire*'
}