export default {
    partnersScholarshipsTitle: 'Стипендии партнёров',
    scholarshipsTitle: 'Стипендии французского Правительства при Посольстве',
    master: 'Мастер',
    masterList1: '',
    masterList2: '',
    masterList3: '',
    ostrogradski: 'Остроградский',
    vernadski: 'Вернандский',
    metchnikov: 'Мечников',
    scholarshipSubmit: ' Подать заявку ',
    ostrogradskiTitle: 'СТИПЕНДИАЛЬНАЯ ПРОГРАММА ОСТРОГРАДСКОГО - Мобильность молодых ученых',
    vernadskiTitle: 'СТИПЕНДИАЛЬНАЯ ПРОГРАММА ВЕРНАНДСКОГО 2023 - обучение в совместной франко-российской аспирантуре',
    masterTitle: 'СТИПЕНДИАЛЬНАЯ ПРОГРАММА ФРАНЦУЗСКОГО ПРАВИТЕЛЬСТВА, ПРЕДЛАГАЕМАЯ ПОСОЛЬСТВОМ ФРАНЦИИ В РОССИИ',
    masterCalendar: 'Прием заявок на стипендию Мастера открыт с 27 ноября 2023 до 23 февраля 2024 года.',
    metchnikovTitle: 'СТИПЕНДИАЛЬНАЯ ПРОГРАММА МЕЧНИКОВА - Мобильность ученых',
    metchnikovCalendar: 'Прием заявок на стипендию имени Мечникова открыт с 22 сентября до 14 января 2024 года.',
    vernandskiTitleSec: 'Прием заявок на стипендию Вернадского не планируется в 2023-2024 учебном году.',
    ostrogradskiCalendar: 'Прием заявок на стипендию Остроградского открыт с 22 сентября до 14 января 2024 года.',
    howToApplyAudencia: "Кандидаты должны отправить заявку candidatures.bourses@bgfrussie.ru согласно установленному календарю, каждый документ в формате .pdf и размером не более 2 МБ каждый.",
    howToApplyMaster: 'Кандидаты должны отправить заявку candidatures.bourses@bgfrussie.ru согласно установленному календарю, каждый документ в формате .pdf (за исключением формы <a href="/forms/Formulaire_Candidature_Master_2024.xlsx">Заполненная анкета</a>, которая должна быть отправлена в формате Excel) и размером не более 2 МБ каждый.',
    howToApplyMetchnikov: 'Требуемые документы, кроме <a href="/forms/Formulaire_Candidature_Metchnikov_2024.xlsx">Заполненная анкета</a>, должны быть выслано <u>единым документом в формате PDF, на французском или на английском языке, и его размер не должен превышать 3 Мо</u>.<br>Заполненная анкета должна быть отправлена в формате Excel.<br>Просьба направлять заявку на участие в конкурсе и конкурсную документацию в электронной форме candidatures.bourses@bgfrussie.ru в теме <u>«Candidature_Metchnikov_2024»</u>.',
    howToApplyOstrogradski: 'Требуемые документы, кроме <a href="/forms/Formulaire_Candidature_Ostrogradski_2024.xlsx">Заполненная анкета</a>, должны быть выслано <u>единым документом в формате PDF, на французском или на английском языке, и его размер не должен превышать 3 Мо</u>.<br>Анкета должна быть отправлена в формате Excel.<br>Просьба направлять заявку на участие в конкурсе и конкурсную документацию в электронной форме candidatures.bourses@bgfrussie.ru в теме <u>«Candidature_Ostrogradski_2024»</u>.',
    program: 'Программа',
    forCandidats: 'Для кандидатов',
    columnTitle1: 'Требования к кандидату',
    columnTitle2: 'Список требуемых документов',
    columnTitle3: 'Преимущества стипендии',
    metchnikovProgramText: 'Стипендиальная программа французского правительства, предлагаемая Посольством Франции в России,\n' +
        '                    осуществляется на конкурсной основе. Она способствует развитию студенческой и научной мобильности из России во Францию.\n' +
        '                    Программа предназначена для российских студентов с отличными учебными результатами.\n' +
        '                    Программа поддерживает учебные и профессиональные индивидуальные проекты, которые должен быть четкими и амбициозными.',
    formMetchnikov: '<a href="/forms/Formulaire_Candidature_Metchnikov_2024.xlsx">Заполненная анкета</a>',
    passport: 'Копия действующего загранпаспорта (только страница с фотографией).',
    cv: 'Резюме',
    visa: 'Бесплатная шенгенская виза',
    metchnikovTableItem1: 'Учёная степень кандидата наук или выше',
    metchnikovTableItem2: 'Статус работника российской государственной или частной научной организации или российского высшего учебного заведения',
    metchnikovTableItem3: '',
    metchnikovTableItem4: 'Cписок публикаций',
    metchnikovTableItem5: 'Описание проекта (просьба указать предполагаемые сроки пребывания во Франции)',
    metchnikovTableItem6: 'Письма с выражением поддержки от директора (руководителя) российской лаборатории и директора (руководителя) принимающей французской лаборатории',
    metchnikovTableItem7: 'Стипендия в размере около 1 704 евро в месяц если защита диссертации произошла &#60; 5 лет, около 2055 евро в месяц если защита диссертации произошла &#62; 5 лет (от 2 до 3 месяцев) в период 01/05/2024 – 31/12/2024.',
    metchnikovTableItem8: 'Оплата транспортных расходов Россия – Франция – Россия',
    // metchnikovFileText1: 'Требуемые документы, кроме анкеты, должны быть выслано единым документом в формате PDF,\n' +
    //     '                        на французском или на английском языке, и его размер не должен превышать 2 Мо.\n' +
    //     '                        Анкета должна быть отправлена в формальном Excel.<br />',
    // metchnikovFileText2: 'в теме [Candidature_Metchnikov_2021],\n' +
    //     '                        чтобы Ваше приложение было принято к сведению.',
    metchnikovName: 'Илья Ильич Мечников',
    metchnikovDescription: '(1845-1916) — русский и французский зоолог, анатом, физиолог и патолог, бактериолог.\n' +
        '                            С 1887г. возглавлял лабораторию в Институт Пастера.\n' +
        '                            1908 году Нобелевская премия по физиологии и медицине была присуждена одновременно\n' +
        '                            Мечникову и Паулю Эрлиху за открытие фагоцитоза.',
    ostrogradskiProgramText1: 'В рамках программы "Остроградский", направленной на оказание поддержки мобильности молодых ученых,\n' +
        '                    Посольство Франции в России присудит гранты (стипендии) <b>аспирантам из российских ВУЗов и научных организаций,</b>\n' +
        '                    осуществляющим исследовательскую деятельность <b> во Франции</b> по приглашению лаборатории высшего учебного заведения,\n' +
        '                    научной организации или предприятия Франции сроком <b> от 2 до 4 месяцев.</b>',
    ostrogradskiProgramText2: 'Действие программы распространяется на все научные дисциплины.\n' +
        '                    Лауреаты Стипендиальной программы Мечников к участию в программе «Остроградский» не допускаются.',
    ostrogradskiTableItem1: 'Гражданство Российской Федерации',
    ostrogradskiTableItem2: 'Статус Аспиранта в России',
    ostrogradskiTableItem3: 'Иметь приглашение от французской организации',
    ostrogradskiForm: '<a href="/forms/Formulaire_Candidature_Ostrogradski_2024.xlsx">Заполненная анкета</a>',
    vernadskiForm: 'Заполненная анкета (смотрите ниже)',
    ostrogradskiTableItem5: 'Cписок публикаций (если есть)',
    ostrogradskiTableItem6: 'Описание проекта (просьба указать предполагаемые сроки пребывания во Франции; примерно 1 до 3 страниц)',
    ostrogradskiTableItem7: 'Письмо-приглашение или свидетельство о приеме от французского вуза или лаборатории',
    ostrogradskiTableItem8: 'Письма с выражением поддержки от руководителя российской лаборатории',
    ostrogradskiTableItem9: 'Стипендия в размере около\n' +
        '                                1 004 евро в месяц (от 2 до 4 месяцев) в период 01/05/2024 – 31/12/2024.',
    ostrogradskiTableItem10: 'Бесплатная страховка, покрывающая весь период пребывания во Франции с первого до последнего дня.',
    ostrogradskiTableItem11: 'Оплата транспортных расходов Россия – Франция – Россия',
    ostrogradskiName: 'Михаил Васильевич Остроградский',
    ostrogradskiDescription: '(1801-1861) — российский математик и механик украинского происхождения,\n' +
        '                            признанный лидер математиков Российской империи в 1830—1860-е годы.\n' +
        '                            Свои занятия математикой он начал в Харьковском Университете,\n' +
        '                            а затем продолжил их в Париже,\n' +
        '                            где познакомился и подружился с известными французскими математиками Коши,\n' +
        '                            Бине, Фурье и Пуассоном. Вернувшись на родину,\n' +
        '                            он преподавал в училище Морского кадетского корпуса,\n' +
        '                            в Николаевских инженерных академиям и Главном артилерийском училище Санкт-Петербурга.\n' +
        '                            Основные работы Остроградского относятся к прикладным аспектам математического анализа,\n' +
        '                            механики, теории магнетизма, теории вероятностей.\n' +
        '                            Он внёс также вклад в алгебру и теорию чисел.',
    vernadskiProgramText1: 'В рамках программы "Вернадский", направленной на оказание поддержки мобильности молодых ученых,\n' +
        '                    Посольство Франции в России присудит в 2021 году гранты (стипендии) аспирантам,\n' +
        '                    проходящим обучение в совместной франко-российской аспирантуре.',
    vernadskiProgramText2: 'Грант покрывает расходы по пребыванию во Франции в течение 3 академических лет (4 календарных года):\n' +
        '                    ориентировочно 3 месяца в первый год, 6 месяцев во второй и третий год,\n' +
        '                    3 месяца в последний год, поочерёдно с пребыванием в России.\n' +
        '                    Предложение адресовано кандидатам, получившим магистерский диплом летом 2021.',
    vernadskiProgramText3: 'За исключением особых случаев, досье с предусматриваемым обучением менее указанного срока рассматриваться не будут.',
    vernadskiProgramText4: 'Кандидатская диссертация при совместном франко-российском научном руководстве может быть написана по любой преподаваемой во Франции научной дисциплине',
    vernadskiTableItem1: 'Наличие диплома о высшем образовании в России (магистратура или специалитет), выданного не позднее июня 2021 года',
    vernadskiTableItem2: 'Российское гражданство и статус студента российского вуза',
    vernadskiTableItem3: 'Написание кандидатской диссертации с двойным научным франко-российским руководством',
    vernadskiTableItem4: 'Хорошее знание французского языка (или английского языка)',
    vernadskiTableItem5: 'Описание научного проекта (2-5 страниц), включающее тематику, текущее состояние знаний, ' +
        'затронутые вопросы, применяемую методику,распределение работ между двумя лабораториями',
    vernadskiTableItem6: 'Заверенная нотариально или российским вузом копия имеющегося российского диплома о высшем образовании (напр. бакалавриат) и его заверенный нотариально или российским вузом перевод',
    vernadskiTableItem7: 'Подтверждение или предварительное подтверждение о зачислении во французский вуз [1]',
    vernadskiTableItem8: 'Рекомендательные письма от французского и российского руководителя аспирантуры',
    vernadskiTableItem9: 'Копия студенческого билета или трудовой книжки (без перевода)',
    vernadskiTableItem10: 'Письмо от обоих руководителей совместной аспирантуры с подтверждением планируемого подписания Соглашения об обучении в совместной аспирантуре либо само Соглашение',
    vernadskiTableItem11: 'Социальное страхование',
    vernadskiTableItem12: 'Бесплатное зачисление в университет',
    vernadskiTableItem13: 'Предоставление на приоритетной основе места в общежитии университета (за исключением университетов Парижа)',
    vernadskiTableItem14: 'Стипендия в размере 1415 евро в месяц предоставляется на период максимум 6 месяцев в год по следующей схеме: 3 месяца начиная с 01.10.2021 + 6 месяцев в 2022 году + 6 месяцев в 2023 году + 3 месяца в 2024 году',
    vernadskiName: 'Владимир Иванович Вернадский',
    vernadskiDescription: '(1863-1945) — русский минеролог и химик, один из основателей современной геохимии.\n' +
        '                            В 1926 году он ввел понятие « ноосфера » и вывел гипотезу о великой геологической силе,\n' +
        '                            способной воздействовать на Землю. Он был одним из первых ученых,\n' +
        '                            говоривших о негативном воздействии деятельности человека на окружающую среду и климат.\n' +
        '                            В период с 1922 по 1926 год Вернадский работал в Париже.\n' +
        '                            Читал лекции в Сорбонне. На французском языке вышел его фундаментальный труд «Геохимия».',
    // rules: 'ПРАВИЛА И ПРОЦЕДУРЫ',
    masterTitle1: 'О программе',
    masterTitle2: 'Критерии отбора',
    masterTitle3: 'Процедура отбора',
    masterText1: 'Стипендиальная программа французского правительства, предлагаемая Посольством Франции в России,' +
        ' осуществляется на конкурсной основе. Она способствует развитию студенческой и научной мобильности из России во ' +
        'Францию. Программа предназначена для российских студентов с отличными учебными результатами.' +
        ' Программа поддерживает учебные и профессиональные индивидуальные проекты, которые должен быть четкими и амбициозными. \n',
    nationalityText: '<b>Национальность: </b> в программе могут принять участие кандидаты <span class="underlineText">с российским гражданством</span>. Кандидаты с двойным гражданством, одно из которых французское, не допускаются к участию в программе.'
    ,
    requiredLevel: '<b>Требуемый уровень: </b> для участия в программе кандидату необходимо иметь как <span class="underlineText">минимум уровень бакалавриата</span>' +
        ' (либо быть в процессе его получения в текущем учебном году).',
    trainingProgram: '<b>Диплом / программа подготовки: </b> <span class="underlineText">кандидаты, в обязательном порядке, должны быть записаны (или ожидать запись) в магистратуру</span> французского ' +
        'государственного или частного высшего учебного заведения, выдающего государственные или признаваемые' +
        ' государством дипломы. Данная программа не рассчитана на уровень аспирантуры и докторантуры, для которых ' +
        'существуют другие стипендиальные программы Посольства Франции в России.' ,
    multipleScholarships: '<b>Получение нескольких стипендий: </b> стипендия французского правительства не может совмещаться с ученическим ' +
        'договором (contrat d’apprentissage), или договором подготовки специалистов (contrat de professionnalisation), или же с любым другим ' +
        'договором, связанным с обучением стипендиата. Стипендия французского правительства не может быть получена одновременно ни со стипендиями ' +
        'других французских министерств, ни  со стипендией Erasmus+ или Университетской организацией Франкофонии. Если стипендиат' +
        ' окажется в подобной ситуации, он должен будет отказаться от одной из стипендий.',
    languageLevel: '<b>Уровень владения языком: </b> для успешного обучения во Франции кандидаты должны обладать <span class="underlineText">необходимым языковым уровнем</span>. ' +
        'Кандидатуры без подтверждающего уровень документа рассматриваться не будут. ',
    supportingDocuments: '<b>Подтверждающие документы: </b> кандидаты должны в обязательном порядке предоставить все требуемые' +
        ' документы и быть в состоянии доказать их подлинность, в частности, подлинность документов о полученном образовании. ' +
        'Неполные досье документов рассматриваться не будут.' ,
    masterText2: 'Досье кандидатов уже проживающих во Франции не являются приоритетными.',
    masterText11: 'Подача заявок',
    masterText13: '',
    calendar: 'Календарь <br /> Отдел по Науке и Технологиям Посольства Франции в России ежегодно утверждает сроки процедуры.' +
        ' Данный календарь является обязательным. Досье, поданные с нарушением сроков, рассматриваться не будут.',
    lineText1: '- Открытие записи онлайн: 27 ноябрь 2023',
    lineText2: '- Конечная дата записи он-лайн: 23 февраль 2024',
    lineText3: '- Объявление результатов: вторая половина мая 2024',
    lineText4: '',
    masterText3: 'Отбор стипендиатов программы',
    masterText31: 'Процедура отбора координируется отделом стипендий ' +
        '(подразделение отдела по сотрудничеству и культуре Посольства ' +
        'Франции в России). Отдел стипендий отвечает за оценку поданных кандидатами ' +
        'заявок (полных досье).' +
        '  Отдел назначает независимых экспертов, компетентных в различных академических областях,' +
        ' в зависимости от профиля кандидата.',
    masterText32: 'Назначенные эксперты предлагают качественную оценку кандидата и его проекта.' +
        ' Критерии отбора следующие: отличные академические результаты, качество получаемого образование, ' +
        'его связь с желаемым образованием во Франции, целостность академического, профессионального и индивидуального проекта.',
    masterText33: 'Отдел стипендий рассматривает полученные оценки и составляет список лауреатов.',
    masterText34: 'По результатам конкурсного отбора будет сформирован основной и дополнительный список кандидатов на получение стипендии.',
    masterText35: '',
    masterText4: '',
    masterTextTitle4: 'Утрата права на стипендию французского правительства',
    masterDescription41: 'Любой отказ влечет за собой утрату права на стипендию французского правительства.',
    masterDescription42: 'Если студент уже находится во Франции и получает стипендию, отказ влечет за собой обязательство возместить Кампюс Франсу возможных задолженностей.',
    masterDescription43: 'Отбор лауреатов осуществляется исходя из оценки конкретного плана обучения кандидата. ' +
        'В связи с чем, отдел стипендий Посольства Франции в России оставляет за собой право отказать в получении стипендии ' +
        'французского правительства лауреату, в случае если он не поступил на программу, на прохождение которой ему была присуждена стипендия. ',
    masterDescription44: 'Несоблюдение обязательств стипендиата французского правительства, в частности пропуски ' +
        'занятий, неадекватное поведение или отсутствие обратной связи, влечет за собой приостановку стипендиальных выплат.',
    masterDescription45: 'В случае если данная ситуации продолжается, студент окончательно теряет право на получение стипендии французского правительства.',
    masterDescription46: 'Получение стипендии французского правительства подразумевает пребывание стипендиата на территории\n' +
        '                    Франции. Те студенты, которые в рамках образовательного процесса должны проходить обязательные\n' +
        '                    стажировки вне Франции, могут иметь право на получение стипендии при соблюдении ряда условий.',
    masterTextTitle5: 'Стипендиальные выплаты и другие преимущества',
    masterDescription51: 'Учебные стипендии выдаются сроком на 9 месяцев (до 4 месяцев в 2023 году и до 5 месяцев в 2024 году).  Лауреаты учебной стипендии получают ежемесячные выплаты в ' +
        'размере 860€,  к которым добавляются следующие преимущества:',
    masterDescriptionLine1: 'студенческая страховка',
    masterDescriptionLine2: 'долгосрочная студенческая виза (за исключением оплаты услуг визового центра);',
    masterDescriptionLine3: 'процедура Кампюс Франс;',
    masterDescriptionLine4: 'регистрационный взнос в государственном университете (государственные тарифы за запись, 3 770€ за диплом Магистра). ',
    masterDescriptionLine5: 'помощь агентства Кампюс Франс в получении комнаты в студенческом общежитии (распределением комнат занимается CROUS). ' +
        'Количество комнат CROUS ограничено, особенно в Париже;',
    masterDescriptionLine6: 'культурные мероприятия, часть расходов на которые оплачивается Министерством иностранных дел. ',
    masterDescription52: 'Стипендии на социальное покрытие выдаются сроком на 10 месяцев (до 4 месяцев в 2023 году и до 6 месяцев в 2024 году). Лауреаты стипендии на социальное покрытие' +
        ' пользуются всеми теми же преимуществами, что и лауреаты учебной стипендии, за исключением ежемесячных выплат. Лауреаты стипендии ' +
        'на социальное покрытие освобождаются от оплаты государственных регистрационных взносов. Оплата за обучение стипендией не покрывается.',
    masterDescription53: 'Каждый студент, являющийся стипендиатом французского правительства может получить помощь на оплату ' +
        'арены жилья, а также поддержку «VISALE» (caution locative d’Etat), процедура при которой государство выступает гарантом при аренде жилья.',
    helpfulInformation: 'Полезная информация',
    info1: `Вся информация опубликована странице: <a href="/faq">на вопросы - ответы</link>`,
    info2: '',
    info3: 'По текущим вопросам вы можете писать на адрес: <br>' +
        '                    <a href="#">bgf.moscou-amba@diplomatie.gouv.fr</a>',
    masterTextTitle6: 'РЕКОМЕНДАЦИИ ДЛЯ УЧАСТИЯ В КОНКУРСЕ НА УЧЕБНЫЕ СТИПЕНДИИ И СТИПЕНДИИ НА СОЦИАЛЬНОЕ ПОКРЫТИЕ ПОСОЛЬСТВА ФРАНЦИИ В РОССИИОБЩИЕ УСЛОВИЯ',
    masterDescription61: 'В программе могут принимать участие исключительно студенты с <b>российским гражданством.</b> ' +
        'Студенты с двойным гражданством (французским и российским) к участию в конкурсе не допускаются.',
    masterDescription62: '<b>Возрастные ограничения</b> для участия <b>отсутствуют.</b>',
    masterDescription63: 'Уровень образования кандидатов должен соответствовать требуемому уровню для поступления на ' +
        'выбранную программу французского высшего учебного заведения.',
    masterDescription64: 'Кандидаты могут подавать заявки на все преподаваемые во Франции дисциплины.',
    ostrogradskiUrl: 'https://ru.ambafrance.org/Stipendiya-im-M-V-Ostrogradskogo-dlya-aspirantov',
    vernadskiUrl: 'https://ru.ambafrance.org/Stipendiya-im-V-I-Vernadskogo-dlya-aspirantov',
    metchnikovUrl: 'https://ru.ambafrance.org/Stipendiya-im-I-I-Mechnikova-Nauchnye-obmeny',
}