import home from "./ru/home";
import header from "./ru/header";
import scholarships from "./ru/scholarships";
import faq from "./ru/faq";
import contacts from "./ru/contacts";
import breadcrumbs from "./ru/breadcrumbs";
import identity from "./ru/identity";
import hasAccountModal from "./ru/hasAccountModal";
import profile from "./ru/profile";
import project from "./ru/project";
import application from "./ru/application";
import blog from "./ru/blog";
import master from "./ru/master";
import edhec from "./ru/edhec";
import audencia from "./ru/audencia";
import cuf from "./ru/cuf";
import footer from "./ru/footer";
import resetPassword from "./ru/resetPassword";
import newMessage from "./ru/newMessage";

export default {
    ...header,
    ...home,
    ...scholarships,
    ...faq,
    ...contacts,
    ...breadcrumbs,
    ...identity,
    ...hasAccountModal,
    ...profile,
    ...project,
    ...application,
    ...blog,
    ...master,
    ...edhec,
    ...cuf,
    ...footer,
    ...resetPassword,
    ...newMessage,
    ...audencia
}