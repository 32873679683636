export default {
    scholarshipAudencia: 'Совместная стипендия с AUDENCIA business school',
    audenciaTitle: 'Audencia Business School и Посольство Франции в России запускают совместную стипендиальную программуна 2023-2025 учебный год',
    audenciaText1: 'Условия участия :',
    audenciaText11: 'Являться гражданином Российской Федерации и обучаться в одном из вузов России ;',
    audenciaText12: 'Подать заявку на первый на программу Master in Management Grande Ecole в Audencia Business School (программа рассчитанана 2 года);',
    audenciaText13: 'Иметь отличную успеваемость в университете, а также иметь внятные академические стремления и четкие профессиональные цели.',
    audenciaText2: 'Преимущества стипендии :',
    audenciaText21: '50%  скидка на оплату обучения по магистерской программе Master in Management Grande Ecole;',
    audenciaText22: 'Стипендия на социальное обеспечение, предоставляемая на   10  месяцев Посольством Франции в России.' +
        ' Студент получает статус стипендиата французского правительства (бесплатная виза, бесплатные регистрационные сборы,' +
        '  приоритет при получении комнаты в студенческом общежитии, студенческая социальная страховка).',
    audenciaText3: 'Сроки подачи заявок на предоставление стипендии',
    audenciaText31: 'Студенты должны подать заявку на обучение по предложенной магистерской программе до 01/02/2023.',
    audenciaText32: 'Студенты должны составить мотивацонное эссе о том, почему они хотят учиться менеджменту во Франции.',
    audenciaInfo1: 'Подать заявку :',
    audenciaInfo3: 'После подачи заявки сообщить представителю Audencia Business School, Наталие БУРАКОВОЙ о подаче заявки с преимущественным правом рассмотрения для французских стипендиатов.',
    audenciaQuestions: 'Вопросы направлять по адресу:',
    audenciaQuestion1: 'Дополнительная информация на сайте <a href="https://master.audencia.com/en/programmes-english/master-management-grande-ecole/"> Audencia Business School:</a> ',
    audenciaQuestion2: ' Представитель Audencia Business School в России, Наталья Буракова : <a href="russia@audencia.com">russia@audencia.com</a>',
}