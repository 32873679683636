<template>
  <div class="md-app-container login-page change-password">
    <md-card class="change-password-form-wrapper">
        <div class="modal_header">
            <span class="close" @click="close" >&#10006;</span>
            <slot name="header"/>
        </div>
      <form action="" @submit.prevent="resetPassword">
      <md-ripple>
        <md-card-header class="login-header">
          <div class="md-title">Nouveau mot de passe</div>
          <p>Saisissez le code d'accés reçu par Email et créez un nouveau mot de passe</p>
        </md-card-header>
        <md-card-content>
          <md-field md-inline>
            <img class="login-input-icon" src="../../assets/images/access-code.png" alt="Email">
            <md-input type="email" v-model="email" placeholder="Email *" required></md-input>
          </md-field>

          <md-field md-inline>
            <img class="login-input-icon" src="../../assets/images/access-code.png" alt="">
            <md-input v-model="accessCode" placeholder="Code d`accés *" required></md-input>
          </md-field>

          <md-field md-inline>
            <img class="login-input-icon" src="../../assets/images/lock.png">
            <md-input type="password" v-model="password" placeholder="Nouveau mot de passe *" required></md-input>
          </md-field>

          <md-field md-inline class="confirm-pass">
            <img class="login-input-icon" src="../../assets/images/lock.png">
            <md-input type="password" v-model="confirmPassword" placeholder="Confirmez le mot de passe *" required></md-input>
          </md-field>

          <p>Le mot de passe doit contenir au moins 10 charactères, une lettre majuscule, un chiffre et un charactère spécial</p>
        </md-card-content>


        <Messages :messages="messages"></Messages>
        <Errors :errors="errors"></Errors>
        <md-button type="submit" class="md-raised">
          <p>Confirmer</p>
        </md-button>
      </md-ripple>
      </form>
    </md-card>
  </div>
</template>

<script>

import Errors from "@/components/Errors";
import Messages from "@/components/Messages";
import {errors} from "@/mixins/errors"
import {messages} from "@/mixins/messages"
import {printArray} from "@/mixins/printArray"
import  {mapActions} from "vuex"

export default {

  data() {
    return {
      email: null,
      accessCode: null,
      password: null,
      confirmPassword: null,
      has_error: false,
    }
  },
  components: {
    Errors,
    Messages
  },
  mixins: [
    printArray,
    errors,
    messages,
  ],

    methods: {
      ...mapActions('auth', ['changePassword']),
   async resetPassword() {

      this.clearErrors()
      this.clearMessages()
        let clientkey = localStorage.getItem(this.email);

        let data = {
          "Email": this.email,
          "AccessCode": this.accessCode,
          "Password": this.password,
          "ConfirmPassword": this.confirmPassword,
          "Clientkey": clientkey
        };
        if(data.Password === data.ConfirmPassword) {
            await this.changePassword(data)
                .then( res => {
                    if (res && res.success) {
                        if(this.$route.name === 'forgot-password') {
                            this.$router.push({name: 'Login'});
                        } else {
                            this.close();
                        }
                        alert('You have successfully changed your password')
                        localStorage.removeItem(this.email);
                    } else {
                        localStorage.removeItem(this.email);
                       res && this.setErrors([res.error]);
                    }
                })
                .catch(err =>  this.setErrors([err]) )
        } else {
            this.setErrors(['Confirm password is wrong']);
        }

      },
      close() {
          this.$emit('close')
          this.$router.push('/login')
          this.clearErrors()
          this.clearMessages()
      }

  }

}
</script>

<style scoped lang="scss">


.md-card.md-theme-default {
  width: 500px;
  //height: 638px;
  margin-top: 40px;
}

.close {
    cursor: pointer;
}

.modal_header {
    padding: 20px 20px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.md-card {
  padding: 20px 30px 50px 50px;
}

.login-page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;


  .login-header {
    padding: 20px 0 28px;

    p {
      text-align: left;
      width: 347px;
      height: 49px;
      left: 50px;
      right: 103px;
      top: 120px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      opacity: 0.5;

    }
  }

  .login-input-icon {
    position: absolute;
    top: 53% !important;
    left: 26px;
    transform: translate(0, -50%);
  }

  .md-title {
    font-weight: 900 !important;
    font-size: 36px;
    line-height: 52px;
    color: #000000;
    text-align: left;
  }

  .md-raised {
    background: #0045FF !important;
    border-radius: 6px;
    height: 60px;
    width: 400px;
    margin-bottom: 56px;

    p {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 34px;
      text-align: center;
      color: #FFFFFF;
      text-transform: capitalize;
    }
  }

  .md-field {
    border: 1px solid #CDD0D9;
    height: 60px;
    padding-left: 58px;
    font-size: 18px;
    line-height: 32px;
    border-radius: 6px;
    box-sizing: border-box;
  }
  .confirm-pass {
    margin-bottom: 0;
  }

  p{
    font-size: 12px;
  }
}
</style>