export default {
    partnersScholarshipsTitle: 'Programmes partenaires',
    scholarshipsTitle: 'Bourses du Gouvernement Français',
    master: 'Master',
    ostrogradski: 'Ostrogradski',
    vernadski: 'Vernadski',
    metchnikov: 'Metchnikov',
    scholarshipSubmit: 'Déposer ma candidature',
    ostrogradskiTitle: 'BOURSES OSTROGRADSKI — Mobilité des jeunes chercheurs',
    vernadskiTitle: 'BOURSES VERNADSKY 2023 — Doctorat en co-tutelle de thèse',
    masterTitle: 'PROGRAMME DE BOURSES D’ÉTUDES ET DE COUVERTURE SOCIALE DE L’AMBASSADE DE FRANCE EN RUSSIE',
    masterCalendar: 'L\'appel à candidature de la bourse Master est ouvert du 27 novembre 2023 au 23 février 2024, 23h59 heure française.',
    metchnikovTitle: 'BOURSES METCHNIKOV — Mobilité des chercheurs',
    metchnikovCalendar: 'L\'appel à candidature de la bourse Metchnikov est ouvert du 22 septembre au 14 janvier 2024.',
    vernandskiTitleSec: 'L\'appel à candidature de la bourse Vernadski n’est pas prévu pour l’année 2023-2024.',
    ostrogradskiCalendar: 'L\'appel à candidature de la bourse Ostrogradski est ouvert du 22 septembre au 14 janvier 2024.',
    howToApplyAudencia: 'Les candidats doivent envoyer leur candidature à l’adresse candidatures.bourses@bgfrussie.ru selon le calendrier établi. Chaque document doit être au format .pdf  et d’une taille maximum de 2 Mo.',
    howToApplyMaster: 'Les candidats doivent envoyer leur candidature à l’adresse candidatures.bourses@bgfrussie.ru selon le calendrier établi. Chaque document doit être au format .pdf (excepté le <a href="/forms/Formulaire_Candidature_Master_2024.xlsx">Formulaire de candidature</a> qui doit être renvoyé au format Excel) et d’une taille maximum de 2 Mo.',
    howToApplyMetchnikov: 'Le <a href="/forms/Formulaire_Candidature_Metchnikov_2024.xlsx">Formulaire de candidature</a> doit être envoyé au format Excel.<br>L’ensemble des autres documents demandés est à envoyer <u>sous forme d’un unique fichier PDF rédigé en français ou en anglais, de taille inférieure à 3 Mo et intitulé «Candidature_Metchnikov_2024»</u>.<br>Le fichier Excel et le PDF produit sont à envoyer par courriel à candidatures.bourses@bgfrussie.ru',
    howToApplyOstrogradski: 'Le <a href="/forms/Formulaire_Candidature_Ostrogradski_2024.xlsx">Formulaire de candidature</a> doit être envoyé au format Excel.<br>L’ensemble des autres documents demandés est à envoyer <u>sous forme d’un unique fichier PDF rédigé en français ou en anglais, de taille inférieure à 3 Mo et intitulé «Candidature_Ostrogradski_2024»</u>.<br>Le fichier Excel et le PDF produit sont à envoyer par courriel à candidatures.bourses@bgfrussie.ru',
    program: 'Le programme',
    forCandidats: 'Pour les candidats',
    columnTitle1: 'Conditions d’éligibilité',
    columnTitle2: 'Dossier de candidature',
    columnTitle3: 'Avantages de la bourse',
    metchnikovProgramText: 'Dans le cadre de son programme Metchnikov d’aide à la' +
        ' mobilité des chercheurs, l’Ambassade de France en Fédération de Russie ' +
        'attribue des bourses à des <b> chercheurs russes invités à ' +
        'travailler dans une équipe de recherche française, rattachée à un ' +
        'établissement d’enseignement supérieur, un organisme de recherche ou ' +
        'une entreprise, pour une durée de 2 à 3 mois. </b>  Toutes les disciplines scientifiques sont concernées.\n' +
        '\n' +
        '<br /> <b>Ne sont pas concernées :</b> les personnes acceptées dans le cadre des programmes Ostrogradski ou Vernadski de l’Ambassade pour l’année concernée.',
    formMetchnikov: '<a href="/forms/Formulaire_Candidature_Metchnikov_2024.xlsx">Formulaire de candidature</a>',
    passport: 'Copie du passeport international valide (seulement la page avec la photographie).',
    cv: 'CV',
    visa: 'Gratuité du visa',
    metchnikovTableItem1: 'Avoir le titre de candidat (кандидат наук) ou un titre supérieur',
    metchnikovTableItem2: 'Être employé par un institut d’un organisme de recherche public ou privé ou d’un établissement d’enseignement supérieur russe',
    metchnikovTableItem3: '',
    metchnikovTableItem4: ' Liste de publications',
    metchnikovTableItem5: ' Description du projet (incluant les dates prévues pour le séjour)',
    metchnikovTableItem6: ' Lettres de soutien des directeurs des laboratoires russe (affiliation) et français (accueil)',
    metchnikovTableItem7: 'Durée de la bourse : entre 2 et 3 mois d’allocation (environ 1 704 € par mois si le chercheur a soutenu sa thèse il y a moins de 5 ans, environ 2 055 € par mois si le chercheur a soutenu sa thèse il y a plus de 5 ans) entre le 01/05/2024 et le 31/12/2024.',
    metchnikovTableItem8: 'Prise en charge du voyage aller-retour Russie-France.',
    // metchnikovFileText1: 'L’ensemble des documents demandés, sauf le formulaire de candidature, est à envoyer sous <b>forme d’un unique fichier PDF rédigé en français ou en anglais, et de taille inférieure à 2 Mo,</b> par courriel à',
    // metchnikovFileText2: 'en l’intitulant [Candidature_Metchnikov_2021] pour que votre candidature soit prise en compte. Le formulaire de candidature doit être envoyé au formel Excel.',
    metchnikovName: 'Ilya Ilitch Metchnikov',
    metchnikovDescription: '(Élie Metchnikoff, 1845-1916): zoologiste, anatomiste et bactériologiste russe, naturalisé français. \n' +
        'Il dirigea un laboratoire à l’Institut Pasteur à partir de 1887. En 1908, le Prix Nobel de physiologie ou médecine lui fut décerné pour la découverte de la phagocytose.',
    ostrogradskiProgramText1: 'Dans le cadre de son programme Ostrogradski d’aide à la mobilité des jeunes chercheurs, l’Ambassade de France en Fédération de Russie attribue des bourses à des étudiants <b> inscrits en doctorat en Russie et invités par une équipe de recherche française, </b> rattachée à un établissement d’enseignement supérieur, un organisme de recherche ou une entreprise pour effectuer un séjour d’apprentissage ou de terrain pour une durée <b>de 2 à 4 mois.</b>',
    // ostrogradskiProgramText2: 'Toutes les disciplines scientifiques sont concernées. \n' +
    //     '\n' +
    //     '<p class="program-text"><b>Ne sont pas concernées:</b> les personnes acceptées dans le cadre des programmes Metchnikov ou Vernadski de l’Ambassade pour l’année concernée. </p> \n' +
    //     '\n' +
    //     'Le programme Ostrogradski est compatible avec la participation à un projet PHC Kolmogorov.',
    ostrogradskiProgramText2: 'Toutes les disciplines sont concernées. \n' +
        '\n' +
        '<p class="program-text"><b> Ne sont pas concernés :</b> les personnes acceptées dans le cadre du programme Metchnikov de l’Ambassade pour l’année concernée. </p> ',

    ostrogradskiTableItem1: 'Être de nationalité russe',
    ostrogradskiTableItem2: 'Être inscrit en doctorat en Russie',
    ostrogradskiTableItem3: 'Être invité par un organisme français',
    ostrogradskiForm: '<a href="/forms/Formulaire_Candidature_Ostrogradski_2024.xlsx">Formulaire de candidature</a>',
    vernadskiForm: 'Questionnaire (voir ci-dessous) rempli',
    ostrogradskiTableItem5: 'Liste de publications (si existantes)',
    ostrogradskiTableItem6: 'Description du projet (incluant les dates prévues pour le séjour ; environ 1 à 3 pages)',
    ostrogradskiTableItem7: 'Lettre d’invitation ou attestation d’accueil de l’établissement français',
    ostrogradskiTableItem8: 'Lettres de soutien du directeur de laboratoire russe d’affiliation',
    ostrogradskiTableItem9: 'Durée de la bourse : entre 2 et 4 mois d’allocation (environ \n' +
        '1 004 € par mois) entre le 01/05/2024 et le 31/12/2024',
    ostrogradskiTableItem10: 'Assurance couvrant le séjour en France durant toute sa durée',
    ostrogradskiTableItem11: 'Prise en charge du voyage aller-retour Russie-France',
    ostrogradskiName: 'Mikhaïl Vassiliévitch Ostrogradski ',
    ostrogradskiDescription: '(1861-1862), est un physicien et mathématicien de l’Empire russe.\n' +
        'Il commença ses études de mathématiques à l’université de Kharkov, ' +
        'et les continua ensuite à Paris où il fut en contact étroit et se lia d’amitié ' +
        'et d’estime avec les célèbres mathématiciens français Cauchy, Binet, Fourier et Poisson.' +
        ' De retour dans sa patrie, il enseigna à l’école des cadets de la Marine, à l’Académie ' +
        'du génie Nicolas et à l’école d’Artillerie de Saint-Pétersbourg. Il est célèbre en particulier' +
        ' pour avoir établi, de même que (indépendamment) Gauss et Green, le théorème de flux-divergence, ' +
        'qui permet d’exprimer l’intégrale sur un volume (ou intégrale triple) de la divergence d’un champ ' +
        'vectoriel comme l’intégrale de surface (intégrale double étendue à la superficie qui entoure ce volume) ' +
        'du flux défini par ce champ.',
    vernadskiProgramText1: 'Dans le cadre de son programme d’aide à la mobilité des doctorants dit « Vernadski », ' +
        'l’Ambassade de France en Fédération de Russie attribue des bourses de doctorat' +
        '<b> en cotutelle de thèse </b> pour la rentrée 2021.',
    vernadskiProgramText2: 'Ce financement couvre le séjour en France durant 3 années universitaires (4 années civiles) ' +
        'en alternance avec la Russie (à titre indicatif 3 mois la première année,' +
        ' 6 mois la deuxième et troisième année, 3 mois la dernière année).' +
        ' Sont concernés les étudiants finissant leur master à l’été 2021. Sauf exception motivée,' +
        ' les dossiers prévoyant une durée inférieure ne seront pas examinés.',
    // vernadskiProgramText3: 'Les thèses de doctorat en cotutelle « Vernadski » sont proposées dans l’ensemble des disciplines scientifiques enseignées en France.',
    vernadskiProgramText3: 'Les thèses de doctorat en cotutelle « Vernadski » sont proposées dans l’ensemble des disciplines enseignées en France.',
    vernadskiProgramText4: '<b>Ne sont pas concernées: </b> les personnes acceptées dans le cadre des programmes Metchnikov ou Ostrogradski de l’Ambassade pour l’année concernée.',
    vernadskiTableItem1: 'Être diplômé de l’enseignement supérieur russe au niveau master ou spécialiste au plus tard au mois de juin 2021',
    vernadskiTableItem2: 'Être de nationalité russe et étudiant en Russie ',
    vernadskiTableItem3: 'Choisir pour son doctorat le régime de cotutelle de thèse entre deux établissements russe et français',
    vernadskiTableItem4: 'Posséder de bonnes connaissances en langue française ou anglaise',
    vernadskiTableItem5: 'Description du projet de recherche (2 à 5 pages conseillées), présentant son sujet, un bref état des connaissance, les questions abordées, la méthodologie suivie, la répartition entre les deux laboratoires',
    vernadskiTableItem6: 'Copie traduite et certifiée (par notaire ou par le département des relations internationales de l’université) du diplôme le plus élevé actuel (ex : licence)',
    vernadskiTableItem7: 'Lettre d’admission ou de préadmission dans un établissement d’enseignement supérieur français',
    vernadskiTableItem8: 'Lettres de recommandation des deux directeurs de thèse',
    vernadskiTableItem9: 'Copie de la carte d’étudiant ou livret de travail (sans traduction)',
    vernadskiTableItem10: 'Convention de doctorat en cotutelle de thèse ou courrier des deux directeurs de thèse confirmant qu’elle est en cours d’élaboration ',
    vernadskiTableItem11: 'Prise en charge de la couverture sociale',
    vernadskiTableItem12: 'Prise en charge des frais d’inscription',
    vernadskiTableItem13: 'Accès prioritaire aux résidences universitaires (sauf à Paris où le logement n’est pas systématique)',
    vernadskiTableItem14: 'Durée et déroulement indicatif : 6 mois d’allocation maximum par an (environ 1415 € par mois) selon le schéma suivant : 3 mois à partir du 01/10/2021 + 6 mois en 2022 + 6 mois en 2023 + 3 mois',
    vernadskiName: 'Vladimir Ivanovitch Vernadski',
    vernadskiDescription: '(1863-1945) : minéralogiste et chimiste russe, à l’origine de la géochimie moderne. \n' +
        'Il définit en 1926 la notion de biosphère, qui pose comme hypothèse que la vie est une force géologique qui transforme la Terre. Il fut l’un des premiers scientifiques à envisager l’impact négatif de l’activité humaine sur l’environnement et le climat. \n' +
        'Du printemps 1922 à l’été 1925, il est invité à Paris par le Recteur de la Sorbonne où il donne des séminaires et des conférences qui paraissent en 1924 sous la forme d’un livre en français intitulé "La géochimie".',
    // rules: 'RULES AND PROCEDURES',
    masterTitle1: 'Présentation du programme',
    masterTitle2: 'Conditions d’éligibilité',
    masterTitle3: 'Procédure de sélection',
    masterText1: 'Le programme des bourses du Gouvernement français de l’Ambassade de France en Russie participe à la mobilité étudiante et scientifique de la Russie vers la France. Il s’adresse à des étudiants de nationalité russe ayant un excellent dossier académique. Il soutient un projet académique, professionnel et personnel clair et ambitieux.',
    nationalityText: '<b>Nationalité : </b> ce programme est réservé aux candidats de nationalité russe. Les candidats binationaux, dont l’une des nationalités est française, ne sont pas éligibles.',
    requiredLevel: '<b>Origine des candidatures : </b> ce programme est réservé aux étudiants ayant obtenu ou en voie d’obtention dans l’année universitaire en cours un niveau d’études minimum correspondant à une Licence. ',
    trainingProgram: '<b>Formations concernées : </b> les candidats doivent impérativement être inscrits ou en voie d’inscription dans une formation d’un établissement d’enseignement supérieur français public ou reconnu par l’État dont les diplômes sont reconnus ou visés par l’État à un niveau d’études minimum correspondant à un niveau Master. Ce programme ne s’adresse pas aux formations de niveau doctoral ou postdoctoral. Ces dernières sont concernées par d’autres programmes de bourses de l’Ambassade de France en Russie.',
    multipleScholarships: '<b>Cumul de bourses : </b> une bourse du Gouvernement français n’est pas cumulable avec un contrat d’apprentissage ou de professionnalisation, ou de tout autre contrat de travail lié à la formation de la personne boursière. Une bourse du Gouvernement français ne peut être cumulée ni avec une bourse attribuée par un autre ministère français, ni avec une bourse Erasmus+, ni avec une bourse de l’Agence Universitaire de la Francophonie. Si un lauréat se trouve dans ce cas, il devra renoncer à l’une des deux bourses.',
    languageLevel: '<b>Niveau de langue : </b> les candidats doivent disposer des capacités linguistiques requises pour le bon déroulé de leur formation en France. L’absence de justificatif de niveau de langue entraine la nullité de la candidature. ',
    supportingDocuments: '<b>Documents justificatifs : </b> les candidats doivent impérativement fournir l’ensemble des pièces requises pour une candidature et être en mesure de prouver l’authenticité de ces documents, en particulier les justificatifs de niveaux d’études. Les dossiers de candidature incomplets seront rejetés.',
    masterText2: 'Les candidats résidant déjà en France ne sont pas prioritaires.',
    masterText11: 'Soumission des candidatures',
    masterText13: ' Le calendrier de la procédure est établi chaque année sur décision du service pour la Science la Technologie. <br/>' +
        'Ce calendrier est impératif. <br/>' +
        'Tout dépassement des délais entraine la nullité des candidatures.',
    masterList1: '• Ouverture des candidatures en ligne : 27 novembre 2023',
    masterList2: '• Date limite de candidature en ligne : 23 février 2024',
    masterList3: '• Communication des résultats : deuxième moitié du mois de mai 2024',
    calendar: 'Sélection des boursiers du programme',
    lineText1: 'La procédure de sélection est coordonnée par le service des bourses de l’Ambassade de France en Russie. Ce service est responsable de l’évaluation des candidatures' +
        ' déposées et complètes. Le service désigne des évaluateurs indépendants et compétents dans les domaines académiques déterminés par les dossiers de candidature <br /> <br />' +
        'Les évaluateurs désignés proposent une évaluation de la qualité du candidat et de son projet. <b> Les critères de sélection sont ainsi établis :' +
        ' excellence des résultats, excellence de la formation suivie, cohérence avec ' +
        'la formation souhaitée en France et du projet académique, professionnel et personnel. </b>',
    lineText2: '',
    lineText3: 'Le service des bourses du SSTE étudie les évaluations soumises et établit la liste des lauréats.',
    lineText4: 'Une liste principale des candidats auxquels sont proposées des bourses d’études et de couverture sociale est établie. Une liste complémentaire est également établie.',
    masterText3: 'Résultats ',
    masterText31: 'L’ensemble des candidats ayant soumis une candidature sont informés par voie électronique.',
    masterText32: 'Les  délibérations  ayant  présidé  aux  attributions  de  bourses  sont  strictement  confidentielles. <b> Aucune communication ne peut être faite à ce sujet. </b>',
    masterText33: '',
    masterText34: '',
    masterText35: '',
    masterText4: '',
    masterTextTitle4: 'Perte du bénéfice d’une bourse du gouvernement français ',
    masterDescription41: 'Tout désistement entraine la perte du bénéfice d’une bourse du gouvernement français. ',
    masterDescription42: 'Si la personne est déjà en France et perçoit une allocation, le désistement implique le remboursement des sommes éventuellement dues à Campus France.',
    masterDescription43: 'Les lauréats sont sélectionnés en fonction d’un projet de formation particulier nommément stipulé dans la candidature. Le service ' +
        'des bourses de l’Ambassade de France en Russie se réserve le droit en conséquence de refuser le bénéfice d’une bourse du gouvernement français si' +
        ' la personne lauréate ne parvient pas à intégrer la formation envisagée pour laquelle elle a été sélectionnée.',
    masterDescription44: 'Les contraventions aux obligations des boursiers du gouvernement français, en particulier le manque' +
        ' d’assiduité, les comportements inadéquats ou le défaut de communication entrainent la suspension de la bourse du gouvernement français.' +
        ' La persistance de cette situation entraine la perte définitive de la bourse du gouvernement français. Cette décision est prise uniquement par' +
        ' l’Ambassade de France en Russie.',
    masterDescription45: 'Le bénéfice d’une bourse du gouvernement français est lié à la présence de la personne boursière ' +
        'sur le territoire français. Les étudiants ayant des stages obligatoires, prescrits par leur formation, pouvant se dérouler' +
        ' hors du territoire français peuvent, sous conditions, bénéficier de leur bourse.',
    masterDescription46: '',
    masterTextTitle5: 'Prestations ',
    masterDescription51: 'Les bourses d’études sont attribuées en principe pour 9 mois (jusqu’à 4 mois en 2023 et 5 mois en 2024). ' +
        'Les boursiers d’études perçoivent une allocation d’entretien mensuelle de 860€,' +
        ' à laquelle s’ajoute la prise en charge de plusieurs prestations :',
    masterDescriptionLine1: 'la couverture sociale étudiante.',
    masterDescriptionLine2: 'le visa long séjour étudiant (hors frais de dossier des centres de visas) ;',
    masterDescriptionLine3: 'la procédure Études en France ;',
    masterDescriptionLine4: 'les frais d’inscription dans une université publique (droits nationaux d’inscription, 3 770€ pour un diplôme de master).',
    masterDescriptionLine5: 'Possibilité d’accéder aux logements dans les résidences étudiantes gérées par les CROUS à l’aide de Campus France. ' +
        'Les places en logement universitaire CROUS sont limitées en nombre, en particulier en région parisienne ;',
    masterDescriptionLine6: 'Des activités culturelles dont une partie des coûts est prise en charge par le Ministère des affaires étrangères.',
    masterDescription52: 'Les bourses de couverture sociale sont attribuées en principe pour <b>10 mois</b> (jusqu’à 4 mois en 2023 et 6 mois en 2024).' +
        ' Les boursiers de couverture sociale bénéficient des mêmes avantages que les boursiers d’études mais ' +
        'ne perçoivent pas d’allocation d’entretien. Les boursiers de couverture sociale bénéficient de la gratuité' +
        ' des frais nationaux d’inscription. <b>Les frais de formation ne sont pas pris en charge.</b>',
    masterDescription53: 'Tout étudiant boursier du gouvernement français peut bénéficier des aides au logement ainsi que des dispositifs « clé » et « VISALE » (caution locative d’État).',
    helpfulInformation: 'Renseignements pratiques ',
    info1: 'Toutes les informations peuvent être consultées sur le page : <a href="/faq?lang=fr">FAQ</a>',
    info2: '',
    info3: 'Pour toute question d’ordre pratique, vous pouvez vous adresser à l’adresse suivante : <br />' +
        '<a href="#">bgf.moscou-amba@diplomatie.gouv.fr</a>',
    masterTextTitle6: 'CONSEILS AUX CANDIDATS. CONDITIONS GÉNÉRALES',
    masterDescription61: 'Le programme est exclusivement ouvert aux candidats de nationalité russe. Les binationaux (français et russes) ne sont pas autorisés à prendre part à ce concours.',
    masterDescription62: 'Il n’existe <b> pas de limite d’âge </b> pour postuler.',
    masterDescription63: 'Le niveau d’études des candidats doit correspondre aux prérequis pour intégrer ' +
        'la formation visée dans un établissement d’enseignement supérieur français.',
    masterDescription64: 'Les candidats peuvent postuler dans toutes les disciplines enseignées en France.',
    ostrogradskiUrl: 'https://ru.ambafrance.org/Bourse-Ostrogradski',
    vernadskiUrl: 'https://ru.ambafrance.org/Bourses-doctorales-Vernadski',
    metchnikovUrl: 'https://ru.ambafrance.org/Bourses-Metchnikov-sejours-scientifiques',
}