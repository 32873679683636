export default {
    scholarshipCUF: 'Стипендии Французских университетских колледжей',
    cufItalicText: 'Французские Университетские Колледжи в Москве и Санкт-Петербурге являются государственными Высшими Учебными Заведениями,' +
        ' который существует при поддержке Министерства иностранных дел, Министерстве образования, Министерстве Высшего образования и науки' +
        ' и сотрудничестве с французскими высшими учебными заведениями, которые являются партнёрам Колледжа. Обучение в Колледже в течение двух лет,' +
        ' во франкоязычном или русскоязычном отделении, дает возможность получения диплома в области права, истории, социологии, литературы, философии.',
    cufTextForMaster: 'Каждый год у лучших студентов Колледжа (в Москве и в Санкт-Петербурге), представивших проект научного исследования для' +
        ' получения степени магистра, есть возможность получить одну из <b>стипендий французского правительствадля</b> обучения во французском ВУЗе ' +
        'в течение последующего учебного года, на уровне <b>"M2" (Master 2).</b>',
    cufText1: '',
    cufText11: 'Конечная дата записи онлайн: 31 марта 2022.',
    cufText12: 'Объявление результатов: июня 2022.',
    cufText2: '',
    cufText13: '',
    cufText3: 'Подробные информации о колледжах:',
    cufText31: 'В Москве: <a href="http://www.moscuf.org/ru" target="_blank">http://www.moscuf.org/ru</a>',
    cufText32: 'В Санкт-Петербурге: <a href="http://cuf.spbu.ru/" target="_blank">www.cuf.spbu.ru</a>',
    cufPartners: 'Партнёрские вузы: <a>Париж I Пантеон Сорбонна (ParisI - Panthéon Sorbonne), Париж II - Пантеон Ассас' +
        ' (ParisII - Panthéon Assas), Париж IV - Париж Сорбонна (ParisIV - Paris Sorbonne), Париж V - Рене Декарт ' +
        '(ParisV - René Descartes), Париж VIII - Венсен Сан-Дени (ParisVIII - Vincennes Saint-Denis),' +
        ' Высшая школа социальных наук (EHESS), Экс Марсель III - Поль Сезан (Aix-MarseilleIII), Высшая нормальная ' +
        'школа Кашана (l\'ENS Cachan), Высшая нормальная школа Парижа (l\'ENS de Paris)</a>',
    cufFinished: ''
}