export default {
    scholarshipEdhec: 'Bourse cofinancée avec l’EDHEC Business School',
    edhecTitle: 'L’EDHEC Business School et l’Ambassade de France en Russie mettent ' +
        'en place une bourse cofinancée pour l’année universitaire 2023-2024',
    edhecText1: 'Conditions pour participer :',
    edhecText11: 'Etre de nationalité russe et être étudiant en Russie ;',
    edhecText12: 'Postuler à la première année d\'un programme MSc de l’EDHEC ;',
    edhecText13: 'Avoir un excellent niveau universitaire ainsi qu’un projet professionnel construit et pertinent.',
    edhecText2: 'Avantages de la bourse :',
    edhecText21: 'Frais d’inscription et de scolarité pour une année (jusqu’à 27000€) offerts par l’EDHEC ;',
    edhecText22: 'Bourse de couverture sociale pour une durée de 10 mois de la part de l’Ambassade de France' +
        ' en Russie. Cette bourse offre le statut de boursier du gouvernement français (gratuité des frais de visa,' +
        ' priorité pour l’obtention d’une chambre en cité universitaire, sécurité sociale étudiante).',
    edhecText3: 'Date limite de candidature : 31/05/2023',
    edhecText31: 'Les candidats doivent déposer une candidature pour un programme d\'un an (MSc).',
    edhecText32: 'Les candidats acceptés par l\'école doivent régler la première tranche des frais d\'inscription.',
    edhecText33: 'Dans le cas où l\'étudiant obtient une bourse, la première tranche des frais d\'inscription est remboursée.',
    edecInfo1: 'Pour postuler :',
    edecInfo2: 'Plus d’informations :',
    edecInfo3: 'EDHEC Admissions Representative for Russia, Julia Dubina :',
}