<template>
    <div class="lang-changer">
        <span
            v-for="(lang, i) in langs"
            :key="`Lang${i}`"
            class="lang"
            :class="{'lang-active': lang.key === $i18n.locale }"
            @click="change(lang.key)"
        >
            {{ $t(lang.key) }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'lang-changer',
    data: () => ({
        langs: [
            {name: 'РУ', key: 'ru'},
            {name: 'FR', key: 'fr'},
        ]
    }),

    methods: {
        change(key) {
            this.$i18n.locale = key
            localStorage.setItem('langKey', key)
        }
    }
}
</script>

<style lang="scss" scoped>
    .lang-changer {

        .lang {
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            cursor: pointer;

            &:first-child {
                border-right: 1px solid #000000;
                margin-right: 5px;
            }

            &-active {
                color: #0045FF;
            }
        }

    }
</style>