export default {
    resume: 'Curriculum vitae',
    resumeComment: 'Commentaire curriculum vitae',
    lastDegree: 'Dernier diplôme obtenu',
    LastDegreeDateOfGraduation: 'Date d’obtention du dernier diplôme',
    lastDegreeLevel: 'Niveau du dernier diplôme obtenu',
    lastDegreeField: 'Spécialité scientifique du dernier diplôme obtenu',
    lastDegreeSpeciality: 'Spécialité du dernier diplôme obtenu',
    lastDegreeInstitution: 'Établissement du dernier diplôme obtenu',
    lastDegreeRed: 'Mention “С отличием”',
    lastDegreeCertifiedCopy: 'Copie certifiée du dernier diplôme obtenu',
    lastDegreeCertifiedCopyComment: 'Commentaire',
    lastDegreeTranscript: 'Notes du dernier diplôme obtenu',
    lastDegreeTranscriptComment: 'Commentaire',
    doctorateInRussia: 'Situation actuelle',
    institutionName: 'Etablissement d\'études actuel',
    institutionCity: 'Ville de l\'établissement',
    doctorateThesisDirectorName: 'Nom du directeur de thèse',
    doctorateThesisDirectorEmail: 'Adresse email du directeur de thèse',
    doctorateScholarship: 'Titulaire d\'une bourse d\'étude',
    yes: 'Oui',
    no: 'Non',
    presentSituation: 'Situation actuelle',
    presentSituationStudent: 'Étudiant',
    presentSituationProfessional: 'En activité',
    presentSituationJobSeeker: 'En recherche d’emploi',
    professionJobName: 'Profession',
    professionDateOfHiring: 'Date de début d’activité',
    professionEmployerName: 'Nom de l’employeur',
    professionEmployerAddress: 'Adresse de l’employeur',
    studentCardOrJobPermit: 'Carte d\'étudiant ou permis de travail',
    studentCardOrJobPermitComment: 'Commentaire',
    formerScholarship: 'Précisez laquelle',
    frenchLanguageLevel: 'Niveau de langue française',
    frenchLanguageCertificate: 'Certificat de langue française',
    frenchLanguageCertificateComment: 'Commentaire',
    otherForeignLanguage: 'Autre langue étrangère',
    otherForeignLanguageCertificate: 'Certificat de langue étrangère',
    otherForeignLanguageCertificateComment: 'Commentaire',
    travelledInFrance: 'A déjà voyagé en France',
    travelledInFranceComment: 'Commentaire',
    formerFrenchScholarship: 'A déjà obtenu une bourse française',
    scholarshipType: 'Type de bourse d\'études',
    year: 'Année d\'étude actelle',
    profileComment: 'Commentaire général sur le profil',
    publicationList: 'Liste des publications',
    publicationListComment: 'Commentaire',
    presentCourseLevel: 'Niveau d\'études actuel',
    presentCourseYear: 'Année d\'études actuelle',
    presentCourseField: 'Domaine d\'études actuel',
    presentCourseSpeciality: 'Spécialité d\'études actuelle',
    presentCourseInstitution: 'Établissement d\'études actuel',
    presentCourseScholarshipHolder: 'Détentreur d`une bourse d`étude',
    presentCourseLastTranscript: 'Dernier bulletin de notes',
    presentCourseLastTranscriptComment: 'Commentaire',
    Lastdegreeinstitut:"Précisez laquelle",
    Lastdegreeinstitut1:"Précisez lequel"

}