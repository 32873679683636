export default {
    faq_title: 'FAQ candidatures BGF',
    body: 'Les informations données ci-dessous sont téléchargeables au format Word sur ce lien : <a class="body-link" href="https://ru.ambafrance.org/Podacha-zayavki-na-stipendiyu-BGF-chasto-zadavaemye-voprosy">https://ru.ambafrance.org/Podacha-zayavki-na-stipendiyu-BGF-chasto-zadavaemye-voprosy</a> ',
    acceptability: '1. Admissibilité',
    acceptability_text:'La situation internationale actuelle entraîne la suspension de toutes les nouvelles coopérations bilatérales entre la France et la Russie. Il est donc, en théorie, impossible de postuler à cette bourse dans le cadre d’un nouveau double diplôme, sauf si l’institut hôte nous certifie l’inverse.',
    acceptability_text1:'Les étudiants en Master 1 en France peuvent déposer leur candidature aux bourses d’études et de couverture sociale à condition d’avoir un diplôme de l’enseignement supérieur russe. Dans ce cas, il faut fournir une lettre de préadmission en M2 (l’université peut par exemple indiquer « admis en M2 sous réserve de succès aux examens de M1 »). Si l’étudiant change d’université entre le M1 et le M2 il faut fournir une lettre attestant qu’il a déposé sa candidature au M2. Il est nécessaire de joindre les relevés de notes du diplôme russe mais aussi celui du premier semestre du M1 passé en France. Nous conseillons de fournir 2 lettres de recommandation: une de l’université française et une de l’université russe. L’étudiant doit également fournir, comme les autres candidats, les autres documents demandés (lettre de motication, CV, etc.).',
    general:'2. Généralités',
    general_text:'Tous les documents doivent être téléchargés au format pdf et peser 2 Mo au maximum.',
    general_text1:'Les documents peuvent être traduits et certifiés en français ou en anglais.',
    languages:'3. Langues',
    languages_text: 'Il est important de prouver votre niveau de français si vous allez étudier en français. Un certificat de langue vous est donc demandé. Si vos études sont en anglais, téléchargez votre certification en anglais à la place du certificat de langue française demandé.',
    languages_text1:'Si vous ne possédez pas de certificat (en attente de passation d’examen de langue, titulaire d’une équivalence…), il convient de poster un document (au format pdf) qui remplacera ce certificat et d’ajouter un commentaire dans « commentaires généraux » pour expliquer en quoi ce document est similaire.',
    languages_text2: 'Un certificat de connaissance d’une deuxième langue est ensuite demandé. Si vous ne maîtrisez pas d’autres langues, ajouter le document que vous avez déjà téléchargé dans la section « première langue ».',
    resume_faq:'4. CV, lettre de motivation, lettres de recommandation, certificat de travail',
    resume_text:'Si vous travaillez, votre certificat de travail devra être ajouté à la suite de votre CV dans un même pdf. Si vous êtes sans-emploi, vous joindrez une copie de votre historique d\'emploi (трудовая книжка). Il n’est pas nécessaire de le faire traduire.',
    resume_text1:'1 ou 2 lettres de recommandation vous sont demandées pour candidater à une bourse BGF. Lors du montage de votre dossier, il faudra les rassembler dans un seul pdf que vous placerez dans la rubrique « recommandation ». Il n’y a pas de format officiel pour la lettre de recommandation. Si possible, la lettre peut être tamponnée.',
    resume_text2: 'La lettre de motivation ne peut pas être manuscrite. Pour des raisons de confort de lecture, nous vous prions de ne jamais joindre de documents manuscrits à votre dossier. Il est préférable de nous l’envoyer dactylographiée, en format pdf.',
	resume_text3: 'Le formulaire excel peut être complété en anglais. Vous ne pouvez indiquer qu’un seul domaine d’étude.',
    certificate_of_registration:'5. Certificat d’inscription ou de préinscription',
    certificate_of_registration_body:'Vous avez postulé à plusieurs universités, via le site Campus France par la création d’un seul compte.',
    certificate_of_registration_body1: 'La date de clôture de l’appel pour la demande d’une bourse BGF est souvent antérieure au moment où les universités commencent à accepter les candidatures. Vous ne pouvez alors pas obtenir de certificat d’inscription ou de préinscription dans les temps.',
    certificate_of_registration_text:'  Il suffit de nous envoyer une copie d’écran (au format pdf) des choix que vous avez formulés sur la plateforme « Etudes en France ».',
    certificate_of_registration_text1:' Si l’établissement pour lequel vous avez postulé n’entre pas dans la procédure Campus France, une copie d’écran (au format pdf) de vos conversations par email avec cet établissement, prouvant votre intention de vous inscrire, suffira.',
    certificate_of_registration_text2: 'Si vous avez été admis dans une / des universités avant l’annonce des résultats, il n’est pas nécessaire de nous envoyer ces confirmations. Elles vous seront demandées après qu’une bourse vous aura été octroyée.',
	faq_project:'6. Les choix de formation',
    faq_project_body: 'Vous pouvez planifier jusqu’à 7 choix de formation. Lorsque vous complétez le formulaire au format excel, vous pouvez renseigner les informations pour vos trois vœux prioritaires.',
    send_application: '7. Envoi de votre dossier',
	send_application_text: 'Les candidats doivent envoyer leur candidature à l’adresse candidatures.bourses@bgfrussie.ru selon le calendrier établi. Chaque document doit être au format .pdf (excepté le Formulaire de candidature qui doit être renvoyé au format Excel) et d’une taille maximum de 2 Mo.',
	results: '8. Résultats',
    results_text : 'Vous serez averti par email du résultat de notre sélection que vous soyez lauréat ou non.',
    results_text1: 'Si vous avez été admis dans une / des universités avant l’annonce des résultats, il n’est pas nécessaire de nous envoyer ces confirmations. Elles vous seront demandées après qu’une bourse vous aura été octroyée.',
    faq_help : '9. Aide',
    faq_help_text:'Choisir sa formation en France : <a class="body-link" href="https://www.campusfrance.org/fr/node/1309">www.campusfrance.org/fr/node/1309</a>',
    faq_help_text1:'Programmes de formation en anglais : <a class="body-link" href="https://www.campusfrance.org/fria/taughtie/index.html">www.campusfrance.org/fria/taughtie/index.html</a> ',
    faq_help_text2:'Procédure Campus France pour les étudiants boursiers : <a class="body-link" href="https://ru.ambafrance.org/Demander-un-visa-pour-la-France-en-Russie">https://ru.ambafrance.org/Demander-un-visa-pour-la-France-en-Russie</a>  / <a class="body-link" href="https://www.campusfrance.org/fr/visa-long-sejour-etudiant">https://www.campusfrance.org/fr/visa-long-sejour-etudiant</a>',
    faq_help_text3:'Procédure de demande de visa long séjour étudiant : <a class="body-link" href="https://www.campusfrance.org/fr/visa-long-sejour-etudiant">https://www.campusfrance.org/fr/visa-long-sejour-etudiant</a>',
    faq_help_text4:'Liste des centres de test de français : <a href="https://www.france-education-international.fr/centres-d-examen/carte">https://www.france-education-international.fr/centres-d-examen/carte</a> ',
}