export const errors = {
    data() {
        return {
            errors: {},
        }
    },
    methods: {
        setErrors(errors) {
            this.errors = errors
        },
        getErrors(errorType) {
            if (this.errors[errorType] == undefined || this.errors[errorType].length <= 0) {
                return null
            }

            return this.errors[errorType]
        },
        clearErrors() {
            this.errors = {}
        }
    }
}