export default {
    home: 'Accueil',
    boursesEtudes: 'Учебные стипендии, стипендии ...',
    register: 'Регистрация',
    profile: 'Profil',
    project: 'Projet',
    application: 'Application',
    messages: 'Messages',
    changePassword: 'Changer votre mot de passe',
    back: 'Retour',
    grantFiles: 'Documents du dossier de bourse',
}