import Vue from 'vue';
import VueRouter from 'vue-router';


Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        meta: {layout: 'notAuth'},
        component: () => import('../views/index'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/auth/Login'),
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('../views/auth/ForgotPassword'),
    },
    {
        path: '/registration/:id/:studentId/:profileId',
        name: 'registration',
        meta: {layout: 'notAuth'},
        component: () => import('../views/auth/Registration'),
    },
    {
        path: '/identity',
        name: 'identity',
        meta: {layout: 'main'},
        component: () => import('../views/auth/Registration'),
    },
    {
        path: '/not-application',
        name: 'not-application-identity',
        meta: {layout: 'main'},
        component: () => import('../views/auth/Registration'),
    },
    {
        path: '/profile',
        name: 'profile',
        meta: {layout: 'main'},
        component: () => import('../views/Profile'),
    },
    {
        path: '/student/profile/:id/:studentId/:profileId',
        name: 'student-profile',
        meta: {layout: 'notAuth'},
        component: () => import('../views/Profile'),
    },
    // {
    //     path: '/student/profile/:id/:studentId/:profileId',
    //     name: 'auth-student-profile',
    //     meta: {layout: 'main'},
    //     component: () => import('../views/Profile'),
    // },
    {
        path: '/projects/:applicationId',
        name: 'projects',
        meta: {layout: 'main'},
        component: () => import('../views/Projects'),
    },
    {
        path: '/project/:applicationId/:id',
        name: 'project',
        meta: {layout: 'main'},
        component: () => import('../views/Project'),
    },
    {
        path: '/student/project/:id/:studentId/:profileId',
        name: 'student-project',
        meta: {layout: 'notAuth'},
        component: () => import('../views/Project'),
    },
    // {
    //     path: '/student/project/:id/:studentId/:profileId',
    //     name: 'auth-student-project',
    //     meta: {layout: 'main'},
    //     component: () => import('../views/Project'),
    // },
    {
        path: '/my-account',
        name: 'my-account',
        meta: {layout: 'main'},
        component: () => import('../views/MyAccount'),
    },
    {
        path: '/grant-files',
        name: 'grant-files',
        meta: {layout: 'main'},
        component: () => import('../views/GrantFiles'),
    },
    {
        path: '/application',
        name: 'application',
        meta: {layout: 'main'},
        component: () => import('../views/Application'),
    },
    {
        path: '/scholarships/Master',
        name: 'scholarship-master',
        meta: {layout: 'notAuth'},
        component: () => import('../views/scholarships/Master'),
    },
    {
        path: '/scholarships/Metchnikov',
        name: 'scholarship-metchnikov',
        meta: {layout: 'notAuth'},
        component: () => import('../views/scholarships/Metchnikov'),
    },
    {
        path: '/scholarships/Ostrogradski',
        name: 'scholarship-ostrogradski',
        meta: {layout: 'notAuth'},
        component: () => import('../views/scholarships/Ostrogradski'),
    },
    {
        path: '/scholarships/Vernadski',
        name: 'scholarship-vernadski',
        meta: {layout: 'notAuth'},
        component: () => import('../views/scholarships/Vernadski'),
    },
    {
        path: '/scholarships/Edhec',
        name: 'scholarship-edhec',
        meta: {layout: 'notAuth'},
        component: () => import('../views/scholarships/Edhec'),
    },
    {
        path: '/scholarships/audencia',
        name: 'scholarship-audencia',
        meta: {layout: 'notAuth'},
        component: () => import('../views/scholarships/Audencia'),
    },
    {
        path: '/scholarships/CUF',
        name: 'scholarship-CUF',
        meta: {layout: 'notAuth'},
        component: () => import('../views/scholarships/CUF'),
    },
    {
        path: '/faq',
        name: 'faq',
        meta: {layout: 'notAuth'},
        component: () => import('../views/Faq'),
    },
    {
        path: '/contacts',
        name: 'contacts',
        meta: {layout: 'notAuth'},
        component: () => import('../views/Contacts'),
    },
    /*{
        path: '/blog',
        name: 'blog',
        meta: {layout: 'notAuth'},
        component: () => import('../views/Blog'),
    },*/
    {
        path: '/messages',
        name: 'messages-list',
        meta: {layout: 'main'},
        component: () => import('../views/messages/List'),
    },
    {
        path: '/chat/:id',
        name: 'chat',
        meta: {layout: 'main'},
        component: () => import('../views/messages/Chat'),
    },
    {
        path: '*',
        name: 'not-found',
        meta: {layout: 'main'},
        component: () => import('../views/Application'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router