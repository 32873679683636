import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ru from '../langs/ru.js'
import fr from '../langs/fr.js'

Vue.use(VueI18n)

export const i18n = new VueI18n({
    locale: 'ru',
    messages: {
        ru,
        fr
    }
})