<template>
  <div v-if="messages">
    <p v-for="(message, i) in messages" :key="i">{{ printArray(message) }}</p>
  </div>
</template>

<script>
import {printArray} from "@/mixins/printArray"

export default {
  name: 'Messages',
  props: {
    messages: Array
  },
  mixins: [
    printArray
  ]
}
</script>
