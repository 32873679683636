export const messages = {
    data() {
        return {
            messages: []
        }
    },
    methods: {
        addMessage(message) {
            this.messages.push(message)
        },
        clearMessages() {
            this.messages = []
        }
    }
}