<template>
    <div
        class="header_wrapper"
        :class="{
            'custom-header': !isFaqPage && !isContactsPage && !isHomePage,
            'home-header': isHomePage
        }"
    >
        <div class="header">

            <div>
                <img
                    v-if="isMobile && !userName && !showMobileMenu"
                    src="@/assets/images/burger.svg"
                    alt="menu"
                    class="menu"
                    @click="showMobileMenu = true"
                />
                <img
                    v-if="showMobileMenu"
                    src="@/assets/images/blue-close.svg"
                    alt="close"
                    class="menu"
                    @click="showMobileMenu = false"
                />
                <img v-if="userData" class="header_logo_loged" src="@/assets/images/logo.svg" alt="logo">
                <router-link to="/" class="header_logo" v-else>
                    <img src="@/assets/images/logo.svg" alt="logo">
                </router-link>
            </div>

            <div v-if="!isMobile && !userName" class="header_content">
                <div
                    v-for="(item,i) in menu"
                    :key="i"
                    class="menu-item"
                    :class="{'active-item': activeItem === item.name}"
                    @click="activeMenuItem(item)"
                >
                    {{ $t(item.name) }}
                </div>
                <lang-changer></lang-changer>
            </div>
            <button v-if="userName" class="arrow-btn navbar-button-wrap">
                <router-link to="/messages" class="notify-wrap">
                    <img src="@/assets/images/notification.svg" alt="notify" />
                    <div v-if="unreadMessages" class="circle">{{ unreadMessages }}</div>
                </router-link>
                <img src="@/assets/images/user1.svg" class="user-img" alt="user">
                <button class="drop-down" @blur="close">
                    <p class="user-name pointer" @click="isOpen = !isOpen">{{ userName }}</p>
                    <div class="arrow-btn">
                        <img
                            class="pointer"
                            :class="{'arrow-down': isOpen}"
                            style="margin-left: 15px"
                            src="@/assets/images/arrow-down.png"
                            alt="arrow"
                            @click="isOpen = !isOpen"
                        >
                    </div>

                    <ul
                        v-if="isOpen"
                        class="drop-down_content"
                    >
                        <li @click="logoutUser">{{ $t('logout') }}</li>
                    </ul>
                </button>
<!--                <div v-if="userName" class="drop-down">-->
<!--                    <button class="arrow-btn">-->
<!--                        <img :class="isOpen ? 'arrow-down' : ''" style="margin-left: 11px" src="@/assets/images/arrow-down.png" alt="">-->
<!--                        <div :class="isOpen ? 'show' : 'hide'">-->
<!--                            <p-->
<!--                                class="dropdown-item"-->
<!--                                @click="logoutUser"-->
<!--                            >-->
<!--                                Logout-->
<!--                            </p>-->
<!--                        </div>-->

<!--                    </button>-->
<!--                </div>-->
            </button>
            <!--<button
                v-else
                class="login-btn"
                @click="login"
            >
                {{ $t('login') }}
            </button>-->
        </div>
        <transition name="fade">
            <div v-if="showMobileMenu && isMobile" class="mobile-menu_wrap">
            <div class="mobile-menu">
                <div
                    v-for="(item,i) in menu"
                    :key="i"
                    class="menu-item_mobile"
                    :class="{'active-item': activeItem === item.name}"
                    @click="activeMenuItem(item)"
                >
                    {{ $t(item.name) }}
                </div>
                <lang-changer class="menu-item_mobile"></lang-changer>
            </div>
        </div>
        </transition>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import LangChanger from '../lang-changer'
export default {
    name: 'nav-bar',
    components: {
        LangChanger
    },
    data: () => ({
        isOpen: false,
        isMobile: false,
        showMobileMenu: false,
        menu: [
            {name: 'scholarships'},
            {name: 'faq'},
            //{name: 'blog'},
            {name: 'contacts'},
        ],
        activeItem: ''
    }),

    comments: {
        LangChanger
    },

    computed: {
        ...mapGetters('auth', ['userData']),
        ...mapGetters(['messages', 'unreadMessages']),
        userName() {
           const user = this.userData
           return user ? user.firstName : ''
       },
        isFaqPage() {
            return this.$route.name.includes('faq')
        },
        isContactsPage() {
            return this.$route.name.includes('contacts')
        },
        isHomePage() {
            return this.$route.name.includes('Home')
        },
    },

    watch: {
        '$route.name': {
            immediate: true,
            handler(name) {
                if(name.includes('scholarship')) {
                    this.activeItem = 'scholarships'
                } else if(name.includes('faq')) {
                    this.activeItem = 'faq'
                } else if(name.includes('contacts')) {
                    this.activeItem = 'contacts'
                } /*else if(name.includes('blog')) {
                    this.activeItem = 'blog'
                }*/ else {
                    this.activeItem = ''
                }
            }
        }
    },

    async mounted() {
        if (this.userName && !this.messages.length) {
            await this.$store.dispatch('getMessages')
        }

        this.resizeHandler()
        window.addEventListener('resize', this.resizeHandler)
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.resizeHandler)
    },

    methods: {
        ...mapActions('auth', ['logout']),
        resizeHandler() {
            if(window.outerWidth <= 1024) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },

        /*login() {
            this.$router.push('/login');
        },*/
        async logoutUser() {
            const success = await this.logout()
            if (success) {
                this.$router.push('/')
            }
        },
        close() {
            this.isOpen = false
        },
        activeMenuItem(item) {
            this.activeItem = item.name
            if (item.name === 'scholarships') {
                this.$router.push({name: 'scholarship-master'})
            }
            if (item.name === 'faq') {
                this.$router.push('/faq')
            }
            if (item.name === 'contacts') {
                this.$router.push('/contacts')
            }
            /*if (item.name === 'blog') {
                this.$router.push('/blog')
            }*/
            this.showMobileMenu = false
        }
    }

}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}
.active-item {
    color: #0045FF;
}

.header_logo_loged {
  width: 96px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    max-width: 1440px;
    height: 100px;
    @media(max-width: 1500px) {
        width: 88%;
        max-width: 1270px;
    }

    @media(max-width: 1100px) {
        width: 83%;
    }

    @media(max-width: 1024px) {
        width: 86%;
        height: 70px;
    }

    .pointer {
        cursor: pointer;
    }

    &_wrapper {
        background: #F5F8FD;
        height: 100px;
        padding: 10px 0;
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        @media(max-width: 1024px) {
            height: 70px;
            margin-bottom: 75px;
        }
    }

    .menu {
        cursor: pointer;
        transition: .3s;
        width: 24px;
    }

    &_logo {
        cursor: pointer;

        img {
            width: 96px;
            @media(max-width: 1024px) {
                width: 70px;
                margin-left: 15px;
            }
        }
    }

    &_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 690px;

      @media(max-width: 1100px) {
        max-width: 580px;
      }

        &-item {
            font-size: 16px;
            line-height: 24px;
            cursor: pointer;
        }
    }

    .notify-wrap {
        position: relative;

        .circle {
            position: absolute;
            top: -4px;
            right: -5px;
            width: 15px;
            height: 15px;
            background: #FF4646;
            color: #FFFFFF;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 11px;
            line-height: 18px;
        }
    }

    &_user {
        font-size: 16px;
        line-height: 24px;
    }
    .arrow-btn {
        border: none;
        outline: none;
        background-color: inherit;
        position: relative;
    }

    .arrow-down {
        transform: rotate(180deg);
    }

    .hide {
        display: none;
    }

    .show {
        height: 40px;
        width: 80px;
        position: absolute;
        background-color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1px 1px 1px #F5F8FD;
        top: 20px;
    }

    .dropdown-item {
        width: 100%;
        line-height: 30px;
    }

    .drop-down {
        display: flex;
        align-items: center;
        border: none;
        outline: none;
        background-color: transparent;
        position: relative;

        &_content {
            width: 240px;
            position: absolute;
            border-radius: 0 6px 6px 0;
            border: 1px solid #cdd0d9;
            right: -5px;
            top: 25px;
            list-style: none;
        }

        li {
            display: flex;
            align-items: center;
            height: 54px;
            padding: 0 20px;
            background-color: #FFFFFF;
            border-radius: 0 6px 6px 0;
            color: #000000;
            font-size: 16px;
            line-height: 24px;
            transition: .3s;
            cursor: pointer;

            &:hover {
                background-color: #0045FF;
                color: #FFFFFF;
            }
        }
    }

    .navbar-button-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 125px;
    }


    .navbar-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 40px;
        margin-top: -30px;
        height: 125px;
    }
    .user-img {
        width: 36px;
        height: 36px;
        margin: 0 15px 0 23px;
    }
    .user-name {
        font-weight: normal;
        font-size: 18px;
        line-height: 32px;
    }
    .login-btn {
        width: 110px;
        height: 48px;
        background-color: #FFFFFF;
        border: 1px solid #CDD0D9;
        border-radius: 6px;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        outline: none;
        cursor: pointer;

        @media(max-width: 1024px) {
            width: 80px;
            height: 36px;
            font-size: 12px;
            line-height: 18px;
        }
    }

    .menu-item {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
    }
}
.mobile-menu {
    width: 300px;
    height: 100%;
    background-color: white;
    padding: 30px;

    @media(max-width: 480px) {
        width: 100%;
        padding-right: 50px;
    }

    &_wrap {
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 70px;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 9999;
    }

    .close {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
        img {
            width: 15px;
            cursor: pointer;
        }
    }

    .menu-item_mobile {
        font-size: 20px;
        line-height: 34px;
        margin: 10px 0;
        font-weight: bold;
    }
}
.custom-header {
    @media(max-width: 420px) {
        margin-bottom: 100px;
    }
}
.home-header {
    @media(max-width: 1024px) {
        margin-bottom: 30px;
    }
    @media(max-width: 768px) {
        margin-bottom: 0;
    }
}
</style>

<style lang="scss">
.mobile-menu {
    .lang-changer .lang {
        font-weight: bold!important;
    }
}
</style>
